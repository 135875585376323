import "../style/BlueButtons.css"
import {useState, useEffect, useContext, useRef} from 'react';
import {UserContext} from "../Context/UserContext.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import baseUrl from '../utilities/globalVariables.js'

function BottoniRating(props){
    const userObject = useContext(UserContext);
    const {accessToken, userId} = userObject

    //creo uno stato per il rateValue cliccato 
    const [rateValue, setRateValue] = useState();
    const [error, setError] = useState();
    const [ratingClick, setRatingClick]= useState(false);
    const [previuousRate, setPreviuousRate] = useState();

    const noticeId = props.noticeId;

    const isInitialMount = useRef(true);
    var url = baseUrl;

    function handleRating(e){
        setRatingClick(!ratingClick);
        e.preventDefault();
        if(e.currentTarget.value === "+"){
            setRateValue('+')
        }else if(e.currentTarget.value === "-"){
            setRateValue('-');
        }else{
            return;
        }
    };
    
    useEffect(()=>{//faccio una fecth per sapere cosa avevo votato
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-noticeid", noticeId);
        myHeaders.append("x-userid", userId);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url +"api/v1/notices/single", requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result.message===-1){
                setPreviuousRate(-1)
            }
            else if (result.message=== 1){
                setPreviuousRate(1)
            }
            else{
                setPreviuousRate()
            }
        })
        .catch(error => console.log('error', error));
    },[ accessToken, error])

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
            var urlencoded = new URLSearchParams();
            urlencoded.append("noticeId", noticeId);
            urlencoded.append("userId", userId);
            urlencoded.append("rateValue", rateValue);
        
            var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
        
            fetch(url +"api/v1/notices/single", requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log(result.message);
                setError(result.message)
            })
            .catch(error => console.log('error', error));
        }
        },[ratingClick]);

    return(
        <div style={{textAlign: 'center'}}>
            <div className="buttonCont" >
                <h4>Ti è sembrato utile?</h4>
                <button className={`rating ${previuousRate === 1 ? 'green' : null}`} value="+" onClick={e=>handleRating(e)}><FontAwesomeIcon icon={faThumbsUp} value="+"/></button>
                <button className={`rating ${previuousRate === -1 ? 'red' : null}`} value="-" onClick={e=>handleRating(e)}><FontAwesomeIcon icon={faThumbsDown} value="-"/></button>
            </div>
            {error && <span  className="ratingMessage"> {error} </span>}
        </div>
    );
}
export default BottoniRating;
