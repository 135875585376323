import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../Context/UserContext.js";
import InvioModello from '../Components/InvioModello231.js';

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoDef from "../imgs/logodef.png";

import baseUrl from '../utilities/globalVariables.js'

import SsoButton from "./SsoButton.js";

function LoginFormA(props) {
    //dichiaro gli stati che mi servono solo qua
    const [userOmail, setUserOmail] = useState(""); // frontend l'utente POTEVA inserire username O MAIL per loggarsi. Poi gestisco Backend
    const [password, setPassword] = useState("");
    const [listaUffici, setListaUffici] = useState([]);
    const [ufficioId, setUfficioId] = useState('')
    
    const [errorMessage, setErrorMessage] = useState(null);
    const [displayError, setDisplayError] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);


    const {setLoginPage, setBodyLoginClassName, setBodyLoginClassName2} = props
    //importo context user
    const userObject = useContext(UserContext);
    const { setRuolo, setUsername, setAccessToken, setUserId} = userObject

    
    //stati che gestiscono il passaggio da LoginForm /Segnalazioni Anonime/ Modello 231
    const [login, setLogin] = useState(true)
    const [noRegistration, setNoRegistration] = useState(false);
    const [modello231, setModello231] = useState(false)
    const [confermaUffici, setConfermaUffici] = useState(false)
    
    //stati per inserimento segnalazioni sia anonime che modello231
    const [testo, setTesto]= useState('');
    const [avviso, setAvviso] = useState('');    

    const url = baseUrl;
    let history = useHistory();

    //per il render condizionale delle pagine login o register, uso uno stato loginPage dichiarato in homepage.
    //Qui vado  a dire dopo quanto, a partire dal click sul link, viene cambiato lo stato che da il via ad una renderizzazione istantanea
    const handleLoginRegisterPage = () => {
        setBodyLoginClassName("right");
        setBodyLoginClassName2("left2")
        if (window.innerWidth > 600) {
            // solo su schermo ritardo il cambiamento di qeusto stato
            setTimeout(function () {
                setLoginPage(false);
            }, 350);
        } else {
            // solo su mobile
            setLoginPage(false);
        }
    };
    //per quando premo enter  a fine inserimento pwd
    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            callForLogin();
        }
    };

    /* 
        la fase di login è divisa in due parti perchè ci sono degli utentiufficio che possono avere associati piu di un ufficio, quindi devono fare una scelta
     */
    
    const callForLogin = () => {
        //Controllo FE per l'inserimento della password: così non faccio richieste inutili al BE
        if (!userOmail || userOmail === "") {
            setErrorMessage("Inserire uno Username");
            setDisplayError(true);
            return;
        }

        if (!password || password === "") {
            setErrorMessage("Inserire la Password");
            setDisplayError(true);
            return;
        }

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: userOmail, password: password }),
        };
//----------------------------
        fetch(url + "api/v1/auth/signin", requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            //console.log("data", data);
            if (data.error) {
                //se c'è stato un errore lei è non vuota.
                setErrorMessage(data.error);
                setDisplayError(true);
                return;
            }
            //SE SONO UN UTENTE UFFICIO, CON PIU UFFICI ASSOCIATI. return
            if(data.uffici){
                //console.log(data.uffici)
                setErrorMessage('');
                setListaUffici(data.uffici);
                setConfermaUffici(true)
                //QUI si apre UN MODALE CHE FA SCEGLIERE CON QUALE UFFICIO si vuole loggare PER POI FARE CALLAPI2
                return
            }

            //SE NON SONO UN UTENTE UFFICIO E se non c'è stato un errore mi arriva un data.roles e arrivo qui
            if (data.roles[0] === "ROLE_MODERATOR") {
                setRuolo("moderator");
                window.localStorage.setItem("ruolo", "moderator"); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
            } else if (data.roles[0] === "ROLE_USER") {
                setRuolo("user");
                window.localStorage.setItem("ruolo", "user");
            }else if (data.roles[0] === "ROLE_ADMIN") {
                setRuolo("admin");
                window.localStorage.setItem("ruolo", "admin");
            }else if (data.roles[0] === "ROLE_SUPER") {
                setRuolo("super");
                window.localStorage.setItem("ruolo", "super");
            }
            //SETTO I DATI PER UN UTENTE BASE 
            setUserId(data.id);
            window.localStorage.setItem("userId", JSON.stringify(data.id));

            setAccessToken(data.accessToken);
            window.localStorage.setItem(
                "accessToken",
                JSON.stringify(data.accessToken)
            );

            setUsername(data.username);
            window.localStorage.setItem(
                "username",
                JSON.stringify(data.username)
            );

            window.localStorage.setItem("firstAccess", true); //serve in header

            //IN PIU, SE ERO UN UTENTE UFFICIO CON UN SOLO UFFICIO ASSOCIATO (quindi ho saltato la parte della selezione ufficio) HO ALTRI DATI DA SALVARE:
            if(data.usernameUtenteUfficio){
                window.localStorage.setItem(
                    "usernameUtenteUfficio",
                    JSON.stringify(data.usernameUtenteUfficio)
                );
                window.localStorage.setItem(
                    "idUtenteUfficio",
                    JSON.stringify(data.idUtenteUfficio)
                );
            }
            history.push("/bacheca");
        });
    };

    //SAML LOGIN 
    function callSAMLAPI(SAMLemail){
        //console.log(SAMLemail)
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({SAMLemail: SAMLemail }),
        };

        fetch(url + "api/v1/auth/signinSAML", requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            //console.log("data", data);
            if (data.error) {
                //se c'è stato un errore lei è non vuota.
                setErrorMessage(data.error);
                setDisplayError(true);
                return;
            }
            //SE SONO UN UTENTE UFFICIO, CON PIU UFFICI ASSOCIATI. return
            if(data.uffici){
                //console.log(data.uffici)
                setErrorMessage('');
                setListaUffici(data.uffici);
                setConfermaUffici(true)
                setUserOmail(data.username)
                //QUI si apre UN MODALE CHE FA SCEGLIERE CON QUALE UFFICIO si vuole loggare PER POI FARE CALLAPI2
                return
            }

            //SE NON SONO UN UTENTE UFFICIO E se non c'è stato un errore mi arriva un data.roles e arrivo qui
            if (data.roles[0] === "ROLE_MODERATOR") {
                setRuolo("moderator");
                window.localStorage.setItem("ruolo", "moderator"); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
            } else if (data.roles[0] === "ROLE_USER") {
                setRuolo("user");
                window.localStorage.setItem("ruolo", "user");
            }else if (data.roles[0] === "ROLE_ADMIN") {
                setRuolo("admin");
                window.localStorage.setItem("ruolo", "admin");
            }else if (data.roles[0] === "ROLE_SUPER") {
                setRuolo("super");
                window.localStorage.setItem("ruolo", "super");
            }
            //SETTO I DATI PER UN UTENTE BASE 
            setUserId(data.id);
            window.localStorage.setItem("userId", JSON.stringify(data.id));
            setAccessToken(data.accessToken);
            window.localStorage.setItem(
                "accessToken",
                JSON.stringify(data.accessToken)
            );
            setUsername(data.username);
            window.localStorage.setItem(
                "username",
                JSON.stringify(data.username)
            );
            window.localStorage.setItem("firstAccess", true); //serve in header

            //IN PIU, SE ERO UN UTENTE UFFICIO CON U SOLO UFFICIO ASSOCIATO (quindi ho saltato la parte della selezione ufficio) HO ALTRI DATI DA SALVARE:
            if(data.usernameUtenteUfficio){
                window.localStorage.setItem(
                    "usernameUtenteUfficio",
                    JSON.stringify(data.usernameUtenteUfficio)
                );
                window.localStorage.setItem(
                    "idUtenteUfficio",
                    JSON.stringify(data.idUtenteUfficio)
                );
            }
            history.push("/bacheca");
        });
    }

    function callForLogin2(){ 

    //Controllo FE per l'inserimento della password: così non faccio richieste inutili al BE
    if (!userOmail || userOmail === "" || ufficioId==='') {
        setErrorMessage(`Seleziona l'ufficio!`);
        setDisplayError(true);
        return;
    }
    //console.log('parte la 2')
    const requestOptions = {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username: userOmail, /* password: password, */ ufficioId: ufficioId }),
    };

    fetch(url + "api/v1/auth/signin", requestOptions)
    .then((response) => {
        return response.json();
    })
    .then((data) => {
        //console.log("data2", data);
        if (data.error) {
            //se c'è stato un errore lei è non vuota.
            setErrorMessage(data.error);
            setDisplayError(true);
            setListaUffici([])
            return;
        }
        //se non c'è stato un errore mi arriva un data.roles e arrivo qui
        if (data.roles[0] === "ROLE_MODERATOR") {
            setRuolo("moderator");
            window.localStorage.setItem("ruolo", "moderator"); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
        } else if (data.roles[0] === "ROLE_USER") {
            setRuolo("user");
            window.localStorage.setItem("ruolo", "user");
        }else if (data.roles[0] === "ROLE_ADMIN") {
            setRuolo("admin");
            window.localStorage.setItem("ruolo", "admin");
        }else if (data.roles[0] === "ROLE_SUPER") {
            setRuolo("super");
            window.localStorage.setItem("ruolo", "super");
        }
        setUserId(data.id);
        window.localStorage.setItem("userId", JSON.stringify(data.id));

        setAccessToken(data.accessToken);
        window.localStorage.setItem(
            "accessToken",
            JSON.stringify(data.accessToken)
        );

        setUsername(data.username);
        window.localStorage.setItem(
            "username",
            JSON.stringify(data.username)
        );

        window.localStorage.setItem(
            "usernameUtenteUfficio",
            JSON.stringify(data.usernameUtenteUfficio)
        );
        window.localStorage.setItem(
            "idUtenteUfficio",
            JSON.stringify(data.idUtenteUfficio)
        );

        window.localStorage.setItem("firstAccess", true); //serve in header
        history.push("/bacheca");
    })
    }

    //queste f(x) mi permetteno di renderizzare i 3 diversi form
    function openNoRegistration(){
        setLogin(false)
        setNoRegistration(true);
        setModello231(false)
        azzeraStati()
    }

    function openModello231(){
        setLogin(false)
        setNoRegistration(false);
        setModello231(true)
        azzeraStati()
    }

    function openLogin(){
        setLogin(true)
        setNoRegistration(false);
        setModello231(false)
        azzeraStati()
    }

    function azzeraStati(){
        setDisplayError(false)
        setAvviso('')
        setErrorMessage('')
        setUserOmail('')
        setPassword('')
        setTesto('')
    }

//costruisco la fetch POST per creare nuovo msg
    function handleSubmitSegnalazioneAnonima(){
        if(!testo){
            console.log("campo vuoto");
            setErrorMessage("Inserire un testo");
            setAvviso('')
            return;
        }
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("text", testo);
        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(url + "api/v1/anonymous", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result);
            setErrorMessage('');
            setTesto('');
            setAvviso('Segnalazione inviata!')
        })
        .catch(error => console.log('error', error));
    }


    return (
        <div className="formInput">
            {login &&
            <div>
                <div className="imgMobile">
                    <img src={logoDef} alt="Logo Hermés" />
                </div>
                <h2 className="titoloLogin"> ENTRA IN HERMÈS </h2>
                {!confermaUffici&&<div>
                    {displayError && <p id="errorMessageLogin">{errorMessage}</p>}{" "}
                    {/* //se displayError è true chiamo il component e gli passo che errore è */}
                    <label htmlFor="usernameOmail"> Username: </label>
                    <input
                        style={{marginTop:'1em'}}
                        value={userOmail}
                        onChange={(event) => setUserOmail(event.target.value)}
                        type="text"
                        name="usernameOmail"
                        id="usernameOmail"
                        className="inputLogin"
                    />{" "}
                    <br /> <br />
                    <label htmlFor="password"> Password: </label>
                    <input
                        style={{marginTop:'1em'}}
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        onKeyPress={handleKeypress}
                        type={passwordShown ? "text" : "password"}
                        id="password"
                        name="password"
                        className="inputLogin"
                    />
                    {!passwordShown && (
                        <FontAwesomeIcon
                            icon={faEye}
                            className="showPasswordIcon"
                            onClick={()=>setPasswordShown(!passwordShown)}
                            style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                        />
                    )}
                    {passwordShown && (
                        <FontAwesomeIcon
                            icon={faEyeSlash}
                            className="showPasswordIcon"
                            onClick={()=>setPasswordShown(!passwordShown)}
                            style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                        />
                    )}
                    <br />
                    <br />
                    <button  className="buttonSignin " id="accediButton" onClick={() => callForLogin()} >ACCEDI
                    </button>
                    <br/> 
                    
                    <br />
                    <p className="clickHere" onClick={() => handleLoginRegisterPage()}>
                        Non sei registratə? Clicca qui.
                    </p><br/><br/>

                    <p className="clickHere"  onClick={()=>openNoRegistration()}>Vuoi lasciare una segnalazione senza registrarti? Clicca qui.</p><br/><br/>
                    <p className="clickHere"  onClick={()=>openModello231()}> Forse cercavi il modello 231?</p>
                    <br/>
                    <SsoButton nextFunction={callSAMLAPI}></SsoButton>
                </div>}
            </div>
            }
            {confermaUffici && 
                <div className='sceltaUfficioDiv'>
                    <p> Ciao {userOmail}, scegli con quale Ufficio vuoi entrare:</p>
                    <select onChange={(e)=> setUfficioId(e.target.value)}>
                        <option value=''>Seleziona ufficio:</option>
                        {listaUffici.map(ufficio=>
                            {return <option  key={ufficio.id} value={ufficio.id}>{ ufficio.username} </option>}
                            
                        )}
                        
                    </select>
                    <div className="buttonSignin"  onClick={()=>callForLogin2()}>OK</div> <br/>
                    <div className="buttonSignin"  onClick={()=>{setConfermaUffici(false); setErrorMessage(''); setUserOmail(''); setPassword('') }}>ANNULLA</div>
                    {displayError && <p id="errorMessageLogin">{errorMessage}</p>}{" "}
                </div>
            }
            {noRegistration && 
            <div>
                <p className="headAnonyme">Sei veramente sicurə di voler lasciare una segnalazione senza registrarti?  <br/>
                Ti ricordiamo che è garantito il pieno anonimato anche a seguito della registrazione.<br/>
                Inoltre, se non ti registri, perderai la possibilità di poter vedere come gestiamo la tua segnalazione, di ricevere feedback e/o risposte in forma 
                completamente anonima e non potremo accogliere eventuali successive tue richieste di accesso, cancellazione o modifica dei dati trasmessi, 
                visto che non saranno associati a nessuna utenza.  
                </p>

                <h4 style={{marginTop:'5%', color: '#0077bc'}}>Inserisci la tua segnalazione</h4>
                <textarea className="textAreaAnonyme" value={testo} maxLength="150" onChange={e=> setTesto(e.target.value)}></textarea><br/>
                <button className="blueButtons" type="submit" onClick={()=>handleSubmitSegnalazioneAnonima()} style={{padding:'0.5% 2%'}}> INVIA</button> <br/>
                <div className="anonymBtns">
                    <p  style={{color:'red', marginBottom:'1%'}}>{errorMessage}</p>
                    <p  style={{color:'green', marginBottom:'1%'}}>{avviso}</p>
                    <p className="clickHere"  onClick={()=>openLogin()}>Torna alla pagina di Login </p>
                    <span>o </span><p className="clickHere"  onClick={()=>handleLoginRegisterPage()}>Registrati</p>
                </div>
            </div>}

            {modello231 && <div>
                    <InvioModello></InvioModello>
                    <p className="clickHere"  onClick={()=>openLogin()}>Torna ad Hermès </p>
                </div>
            
            }
            <div className="privacyLegalHomePage"  > 
                <a href="https://www.avrgroup.it/azienda/modello-231/privacy-policy" target="_blank" rel="noreferrer" style={{right:'2vw'}}>Privacy  &amp;  Legal</a>
            </div>
        </div>
    );
}

export default LoginFormA;


