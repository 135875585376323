import React, {useState, createContext} from 'react';

export const RerenderContext = createContext();

export const RerenderProvider = props =>{

    const [renderAfterNotification, setRenderAfterNotification] = useState(false);

    var afterNotificationObject = {
        renderAfterNotification: renderAfterNotification,
        setRenderAfterNotification: setRenderAfterNotification
    };

    return(
        <RerenderContext.Provider value ={ afterNotificationObject}>
            {props.children}
        </RerenderContext.Provider>
    );
}

/*
    contesto chiamato in HEADER che mi fa rirenderizzare INBOX al momento in cui arriva un nuovo messaggio via socket 
*/