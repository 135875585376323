import React, { useState, useEffect, useContext } from "react";
import {useHistory} from "react-router-dom";

import "../style/ModelloPage.css";

import Header from '../Components/Header.js';
import InvioModello from '../Components/InvioModello231.js';
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";
import { UserContext } from "../Context/UserContext.js";

import {faImage} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'

function ModelloPage() {
    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    
    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const [listaAziende, setlistaAziende] = useState ([])
    const [listaVuota, setListaVuota] = useState(`Seleziona un'azienda.`); 
    const [listaRichieste, setListaRichieste] = useState([]) //lista effettiva ricevuta dal db
    const [listaRichiesteFiltrate, setListaRichiesteFiltrate] = useState([]) //stato di appoggio della lista effettiva, serve per non perdere i risultati dopo una ricerca
    
    const [itemImagesArray, setItemImagesArray ] = useState([])
    const [immagini, setImmagini] = useState(false) //stato che uso per il render condizionale delle immagini associate ad una singola richiesta

    const [primaData, setPrimaData] = useState("");
    const [secondaData, setSecondaData] = useState("");
    const [loading , setLoading] = useState(false)


    /* LISTA OGGETTI
    const [listaOggetto, setlistaOggetto]= useState(['Condotte Illecite',"Violazioni di norme","Azioni suscettibili di arrecare pregiudizi patrimoniale o di immagine aziendale","Violazioni del presente Codice Etico","Violazioni del Modello 231","Violazioni di procedure e disposizioni aziendali","Altro"]) */
    var url=baseUrl

    var userController= false;
    var adminController= false;
    var superController = false;

    
    if(ruolo==='user'){ userController=true}
    if(ruolo==='admin'){ adminController=true}
    if(ruolo==='super'){ 
        adminController=true
        superController=true
    }
    
    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])
    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }
    if(accessToken === ""){
        if(window.localStorage.accessToken){
          setRuolo(window.localStorage.ruolo); //sono già stringhe
          setUsername(JSON.parse(window.localStorage.username));
          setAccessToken(JSON.parse(window.localStorage.accessToken));
          setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }

    //get per popolamento elenco scelta aziende
    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
            };
        fetch(url+"api/v1/aziende231", requestOptions)
            .then(response => response.json())
            .then(result => {
               // console.log(result.message);
                setlistaAziende(result.message);
            }
            )
            .catch(error => console.log('error', error));
    },[accessToken])

    //faccio la fetch che chiama le richieste where aziendaid. chiamta all'onchange
    function getListaRichieste(e){
        if(e.target.value===0){
            setListaVuota('Nessuna azienda selezionata')
            return
        }

        setImmagini(false)
        setLoading(true)
        let aziendaValue = e.target.value
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);
        myHeaders.append("x-aziendaid", aziendaValue);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url+"api/v1/richieste231", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("richieste", result.message);
            setListaVuota('')
            setListaRichieste([...result.message])
            setListaRichiesteFiltrate([...result.message]) //stato di appoggio della lista
            setLoading(false)

            if( result.message.length === 0 ) {
                setListaVuota('Non ci sono richieste da poter mostrare.')
                setListaRichieste([])
        }
        })
        .catch(error => console.log('error', error));
    }

    //FILTRAGGI FE PER OGGETTO O TESTO usando stato di appoggio listaRichiesteFiltrate
    function handleSearchSubject(e){
        const searchString =  e.target.value;
        var result = listaRichieste.filter(richiesta => {
            return richiesta.subject.match(new RegExp(searchString, "gi")) //case insensitive
        })
        //console.log("searched",result) 
        setListaRichiesteFiltrate(result)
    }

    function handleSearchText(e){
        const searchString =  e.target.value;
        var result = listaRichieste.filter(richiesta => {
            return richiesta.text.match(new RegExp(searchString, "gi"))
        })
        //console.log("searched",result) 
        setListaRichiesteFiltrate(result)
    }

    //FILTRAGGIO FE PER DATA ( setto gli stati in questa funzione, settandoli faccio partire lo useEffect che segue)
    function handleDateStates(e){
        if(e.target.value){
            if(e.target.id === 'primaData'){
                setPrimaData(new Date(e.target.value))
            }else if(e.target.id === 'secondaData'){
                setSecondaData(new Date(e.target.value))
            }
        }else{
            if(e.target.id === 'primaData'){
                setPrimaData()
            }else if(e.target.id === 'secondaData'){
                setSecondaData()
            }
        }
    }

    useEffect(()=>{
        //console.log("date cercate",primaData, secondaData)
        if(primaData&&!secondaData){
            var result = listaRichieste.filter(richiesta => {
                    var date = new Date(richiesta.createdAt);
                    return (date >= primaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("prima", listaRichiesteFiltrate )

        } 
        if(!primaData&&secondaData){
            let result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.createdAt);
                return ( date <= secondaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("secondo", listaRichiesteFiltrate )

        } 
        if(primaData&&secondaData){
            let result = listaRichieste.filter(richiesta => {
                var date = new Date(richiesta.createdAt);
                return (date >= primaData && date <= secondaData);
                });
            setListaRichiesteFiltrate(result)
            //console.log("between", listaRichiesteFiltrate )
        }
        if(listaRichiesteFiltrate.length===0){
            setListaVuota('Nessuna richiesta soddisfa i parametri di ricerca.')
        }
    },[primaData,secondaData])


    return (
        <div className="Appp2"> 
            <div className="containerRight">
                <Header headImgs='true'></Header>
                <div className="containersVariModello">
                    {/* VISUALIZZA MODELLI SOLO SE SUPERADMIN */}

                    {superController&&<div className="divContainerModello">
                        <div className="headModelloPage">Qui puoi visualizzare tutte le richieste ricevute tramite Modello 231</div>
                        <label htmlFor="listaAziendeValue" style={{display:'none'}}> Seleziona un'azienda </label>
                            <select name="listaAziende" id="listaAziendeValue" onChange={e=> getListaRichieste(e) }>
                                <option value="0">---</option>
                                {listaAziende.map((azienda)=>{
                                return <option key={azienda.id} value={azienda.id}>{azienda.name}</option>
                                })}
                            </select>
                        <div id="containerSearchModello">
                            <label htmlFor="ricercaOggetto" style={{display:'none'}}> Ricerca nell'oggetto </label>
                            <input id="ricercaOggetto" type="text" className="inputRicercaOggetto231" placeholder="Ricerca nell'Oggetto..." onChange={(e)=>handleSearchSubject(e)}></input>o
                            
                            <label htmlFor="ricercaTesto" style={{display:'none'}}> Ricerca nel testo </label>
                            <input type="text" id="ricercaTesto" className="inputRicercaOggetto231" placeholder="Ricerca nel Testo..." onChange={(e)=>handleSearchText(e)}></input>
                        </div>
                        <div id="containerDateModello">
                            <label htmlFor="primaData" style={{display:'inline'}}>Dal:  </label>
                            <input id='primaData' onChange={(e) => handleDateStates(e)} type="date" name="primaData" className="inputRicercaOggetto231" min="2021-01-01"max="2024-12-31"></input>
                            <label htmlFor="secondaData" style={{display:'inline'}}>Al:  </label>
                            <input id='secondaData' onChange={(e) => handleDateStates(e)} type="date" name="secondaData" className="inputRicercaOggetto231" min="2021-01-01"max="2024-12-31"></input>
                        </div>

                        <div className="containerTablesRichieste">
                            <p>{listaVuota}</p>
                            {loading &&<div className="lds-dual-ring"></div>}
                            {listaRichiesteFiltrate.length>0 && listaRichiesteFiltrate.map((item)=>{
                                    //formatto orario creazione
                                    var epoch = item.epoch;
                                    var data = new Date(+epoch).toLocaleString('it-IT');
                                    var orario = data.split(", ")[1].split(":");
                                    orario = orario[0] +":"+ orario[1];
                                    data = data.split(", ")[0];
                                return <div className="tableRichieste" key={item.id} id={item.id}> 
                                            <span> {orario}</span> 
                                            <span> {data}</span>   
                                            {item.immaginis.length>0 &&<div className="mostraImmaginiSpan"  onClick={()=>{setItemImagesArray(item.immaginis); setImmagini(!immagini)}}><FontAwesomeIcon icon={faImage} ></FontAwesomeIcon> Mostra immagini allegate</div>}
                                            <span className="subjectModello231">{item.subject}</span> 
                                            <span className="textModello231"> {item.text}</span> 
                                        </div>
                            })} 
                            {immagini&& <div className="modalImages">{itemImagesArray.map((item,i)=>{
                                let path = baseUrl + item.path
                                return <a key={item.id} href={path} target="_blank" rel="noreferrer">{item.path}</a>
                            }) }
                            <div aria="chiudi lista immagini" id="closeImages" onClick={()=>setImmagini(false)}>&times;</div>
                            </div>}
                            
                        </div>
                    </div>}
                    
                    {userController&&<div className="container231">
                                <InvioModello></InvioModello>
                        </div>}

                    {/* INVIO MODELLO SOLO SE UTENTE */}
                </div>
            </div>
        </div>
    );
}
export default ModelloPage;
