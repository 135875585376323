import React, { useState, useEffect, useContext, useRef } from "react";
import "../style/FilterComponents.css";
import { Link } from "react-router-dom";
import {useHistory} from "react-router-dom";

import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import SingleMessage from "../Components/SingleMessage.js";
import PaginationArrows from "../Components/ArrowsPagination";

import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import baseUrl from '../utilities/globalVariables.js'

function SearchPage(props) {
    const inboxOrSent = props.location.state.inboxOrSent;

    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;

    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const [listaMsg, setListaMsg] = useState([]);
    const [listaVuota, setListaVuota] = useState(true); //gestisce l'avviso nessun msg ricevuto
    const [parametriVuoti, setParametriVuoti] = useState(true); //gestisce l'avviso parametri da inserire per inziare la ricerca

    //stati per costruire la ricerca
    const [senderId, setSenderId] = useState("");
    const [recipientId, setRecipientId] = useState("");
    const [inputtextsearch, setInputtextsearch] = useState("");
    const [primaData, setPrimaData] = useState("");
    const [secondaData, setSecondaData] = useState("");
    const [subject, setSubject] = useState("");

    //stati per la PAGINATION
    const [offsetCounter, setOffsetCounter] = useState(0); //modificato solo in Pagination
    const [dataLimit, setDataLimit] = useState(50); //modificabile anche dallo user un giorno
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0); //mi esce dalla fetch

    //per aprire e chiudere la barra di ricerca
    const [classeFilterContainer, setClasseFilterContainer] =
        useState("filterContainer");
    const [classeBodyFilterContainer, setClasseBodyFilterContainer] = useState(
        "bodyFilterContainer"
    );
    const [classeChevron, setClasseChevron] = useState("angleUp");

    //per il loader
    const [loading, setLoading] = useState(false);

    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])
    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }
    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }

    //se arrivo da inbox o sent cambia fetch
    var inbox;
    var primoUrl=baseUrl

    var url;
    if (inboxOrSent === "inbox") {
        inbox = true;
        url = primoUrl+"api/v1/messages/search/inbox";
    } else if (inboxOrSent === "sent") {
        inbox = false;
        url = primoUrl+"api/v1/messages/search/sent";
    }

    function handleOpeningSearchBarFromChevron() {
        if (classeFilterContainer === "filterContainer--closed") {
        openSearchBar();
        } else if (classeFilterContainer === "filterContainer") {
        closeSearchBar();
        }
    }
    function handleOpeningSearchBar() {
        if (windowWidth > 600) {
        //nello screen deve solo aprire
        openSearchBar();
        }
        if (windowWidth < 600) {
        //nel mobile apre e chiude
        if (classeFilterContainer === "filterContainer--closed") {
            //closed
            openSearchBar();
        } else if (classeFilterContainer === "filterContainer") {
            closeSearchBar();
        }
        }
    }
    function closeSearchBar() {
        setClasseFilterContainer("filterContainer--closed");
        setClasseBodyFilterContainer("bodyFilterContainer--closed");
        setClasseChevron("angleUp down");
    }
    function openSearchBar() {
        setClasseFilterContainer("filterContainer");
        setClasseChevron("angleUp");
        setClasseBodyFilterContainer("bodyFilterContainer");
    }
    
    //only if SCREEN and NOT mobile: il menu in alto si chiude se scrollo in basso
    const prevScrollY = useRef(0);
    const [goingUp, setGoingUp] = useState(false);
    var windowWidth = window.innerWidth;

    useEffect(() => {
        if (windowWidth > 600) {
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (prevScrollY.current < currentScrollY) {
            setGoingUp(false);
            closeSearchBar();
            }
            if (prevScrollY.current > currentScrollY) {
            setGoingUp(true);
            }

            prevScrollY.current = currentScrollY;
        };

        window.addEventListener("scroll", handleScroll, { passive: true });
        return () => window.removeEventListener("scroll", handleScroll);
        }
    }, [goingUp]);

    function deleteAllFilters() {
        setSenderId("");
        setRecipientId("");
        setSubject("");
        setInputtextsearch("");
        setPrimaData("");
        setSecondaData("");
        //setListaVuota(false);
    }
    /* SET TOTALCOUNT: chiamata per sapere il totale dei msg postumo al filtraggio=> serve alla PAGINATION --->getTotalCountInboxOrSent */
    useEffect(() => {
        /*NON PARTE LA FETCH FINCHE NON VIENE INSERITO UN CAMPO */
        if (senderId === "" && recipientId === "" && subject === "" && inputtextsearch === "" && primaData === "" && secondaData === "") {
            setParametriVuoti(true); 
            setListaMsg([]);
            setListaVuota(false);
            setTotalCount(0);
            return;
        }
        /* setto l'attesa: metto loader e mando via tutti gli warnings */
        setLoading(true);
        setParametriVuoti(false);
        setListaMsg([]);
        setTotalCount(0);
        //console.log("partita la 1");

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId);
        myHeaders.append("x-senderid", senderId);
        myHeaders.append("x-recipientid", recipientId);
        myHeaders.append("x-subject", subject);
        myHeaders.append("x-inputtextsearch", inputtextsearch);
        myHeaders.append("x-primaData", primaData);
        myHeaders.append("x-secondaData", secondaData);

        var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        };

        fetch(
        primoUrl+"api/v1/messages/search/totalCount",
        requestOptions
        )
        .then((response) => response.json())
        .then((result) => {
            console.log("TOTALCOUNT", result.count);
            setTotalCount(result.count);

            /* constriusco seconda fetch - che è dinamica e cambia anche in base a SENT or INBOX */
            //console.log("partita la 2");

            var myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("x-to", userId);
            myHeaders.append("x-from", userId);
            myHeaders.append("x-offsetinput", offsetCounter);
            myHeaders.append("x-dataLimit", dataLimit);
            myHeaders.append("x-senderid", senderId);
            myHeaders.append("x-recipientid", recipientId);
            myHeaders.append("x-subject", subject);
            myHeaders.append("x-inputtextsearch", inputtextsearch);
            myHeaders.append("x-primaData", primaData);
            myHeaders.append("x-secondaData", secondaData);

            var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
            };

            fetch(url, requestOptions)
            .then((response) => {
                if (response.status === 200) {
                return response.json();
                } else {
                return;
                }
            })
            .then((result) => {
                if (result.message.length!==0) {
                    //console.log("ricevuti2", result.message);
                    setParametriVuoti(false);
                    setListaMsg(result.message);
                    setListaVuota(false);
                    setLoading(false);
                } else if (result.message.length === 0) {
                    setListaVuota(true); //metterei anche setListaMsg([]); ti servirà nella gestione dei delete
                    setListaMsg([]);
                    setParametriVuoti(false);
                    setLoading(false);
                }
            });
        })
        .catch((error) => console.log("error", error));
    }, [ senderId,  recipientId,  subject,  inputtextsearch,  primaData,  secondaData,  offsetCounter]);

    return (
        <div className="searchPageContainer">
            <div className={classeFilterContainer}>
                <div className="headFilterContainer" onClick={() => handleOpeningSearchBar()} >
                    <Link to="/messaggi">
                        <button className="backButtonSearch">
                            <FontAwesomeIcon icon={faShare}></FontAwesomeIcon> 
                        </button>
                    </Link> 
                    <p>  EFFETTUA UNA RICERCA TRA I MESSAGGI 
                        {inbox && ( <span> RICEVUTI (ANCHE ARCHIVIATI).</span>  )} 
                        {!inbox && <span> INVIATI (ANCHE ARCHIVIATI).</span>} 
                    </p>
                </div>
                <div className={classeBodyFilterContainer}>
                    {inbox && (
                        <div className="boxInputSearch">
                        <label htmlFor="mittente"> Filtra per mittente: </label>
                        <input
                            id="mittente"
                            value={senderId}
                            onChange={(event) => setSenderId(event.target.value)}
                            type="text"
                            name="senderId"
                            className="inputLogin "
                        ></input>
                        <button
                            className="bottoneCancellaData"
                            onClick={() => setSenderId("")}
                        >
                            &times; 
                        </button>
                        </div>
                    )}
                    {!inbox && (
                        <div className="boxInputSearch">
                        <label htmlFor="destinatario"> Filtra per destinatario: </label>
                        <input
                            id="destinatario"
                            value={recipientId}
                            onChange={(event) => setRecipientId(event.target.value)}
                            type="text"
                            name="recipientId"
                            className="inputLogin "
                        ></input>
                        <button
                            className="bottoneCancellaData"
                            onClick={() => setRecipientId("")}
                        >
                            &times; 
                        </button>
                        </div>
                    )}

                    <div className="boxInputSearch">
                        <label htmlFor="oggetto"> Ricerca nell'oggetto: </label>
                        <input
                            id="oggetto"

                        value={subject}
                        onChange={(event) => setSubject(event.target.value)}
                        type="text"
                        name="inputtextsearch"
                        className="inputLogin"
                        ></input>
                        <button
                        className="bottoneCancellaData"
                        onClick={() => setSubject("")}
                        >
                        &times; 
                        </button>
                    </div>
                    <div className="boxInputSearch">
                        <label htmlFor="testo"> Ricerca nel testo: </label>
                        <input
                            id="testo"
                            value={inputtextsearch}
                        onChange={(event) => setInputtextsearch(event.target.value)}
                        type="text"
                        name="inputtextsearch"
                        className="inputLogin  "
                        ></input>
                        <button
                        className="bottoneCancellaData"
                        onClick={() => setInputtextsearch("")}
                        >
                        &times; 
                        </button>
                    </div>
                    <div id="containerFiltriData">
                        <div>
                        <label htmlFor="primaData"> Dal: </label>
                        <div style={{ display: "inline" }} >
                            <input
                            id="primaData"
                            value={primaData}
                            onChange={(event) => setPrimaData(event.target.value)}
                            type="date"
                            className="inputLogin inputData"
                            min="2021-01-01"
                            max="2024-12-31"
                            ></input>
                            <button
                            className="bottoneCancellaData"
                            onClick={() => setPrimaData("")}
                            >
                            &times; 
                            </button>
                        </div>
                    </div>
                    <div>
                    <label htmlFor="secondaData"> Al: </label>
                    <div style={{ display: "inline" }}>
                        <input
                            id="secondaData"
                            value={secondaData}
                        onChange={(event) => setSecondaData(event.target.value)}
                        type="date"
                        className="inputLogin inputData"
                        min="2021-01-01"
                        max="2024-12-31"
                        ></input>
                        <button
                        className="bottoneCancellaData"
                        onClick={() => setSecondaData("")}
                        >
                        &times; 
                        </button>
                    </div>
                    </div>
                </div>
                    <div className="cancellaFiltri">
                        Azzera tutti i filtri:
                        <FontAwesomeIcon
                        icon={faTimes}
                        className="cancellaFiltriIcon"
                        onClick={() => deleteAllFilters()}
                        ></FontAwesomeIcon>
                    </div>
                </div>
                <FontAwesomeIcon  className={classeChevron}  icon={faChevronUp}  onClick={() => handleOpeningSearchBarFromChevron()} ></FontAwesomeIcon>
            </div>
            <div className="resultsContainer">
            {parametriVuoti && ( <p>Inserisci dei parametri per iniziare la ricerca</p>  )}
            {listaVuota && (  <p> Nessun messaggio corrisponde ai criteri di ricerca!</p>  )}
            {loading && <div className="lds-dual-ring"></div>}
            {listaMsg.map((msg) => (
            <SingleMessage  ruolo={ruolo} inboxOrSent={inboxOrSent} key={msg.id} id={msg.id} msg={msg} usernameFrom={msg.username} date={msg.createdAt}  ></SingleMessage> ))}
            {/* <PaginationArrows  totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={dataLimit} offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}  ></PaginationArrows> */}
        </div>
        </div>
    );
}

export default SearchPage;

/* 
IN QUESTA VERSIONE faccio entrambe le fetch in un unico useEffect
    la seconda è condizionata alla prima, quindi ok c'è l'attesa del risultato, 
    ma la prima viene richiamata anche quando viene solo cambiata pagina (quindi non cambiano altri stati, 
    non ci sarebbe bisogno di far partire di nuovo il totalcount)
*/
