import React from 'react';
import {useState, useEffect, useContext} from 'react';
import "../style/StoredContainer.css";

import {UserContext} from "../Context/UserContext.js";
import {RerenderContext} from "../Context/RerenderAfterNewMsgContext.js";
import SingleMessage from "../Components/SingleMessage.js";
import PaginationArrows from "../Components/ArrowsPagination";

import { faClock} from "@fortawesome/free-regular-svg-icons";
import { faUsers} from "@fortawesome/free-solid-svg-icons";
import baseUrl from '../utilities/globalVariables.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function StoredContainer(){
    const userObject = useContext(UserContext);
    const {userId, ruolo, accessToken} = userObject

    
    const afterNotificationObject = useContext(RerenderContext);
    const renderAfterNotification = afterNotificationObject.renderAfterNotification; //stato che uso come dep dello useEffect per renderizzare dopo l'arrivo di una notifica
    var url=baseUrl

    //stato per loader
    const [loading , setLoading] = useState(true)

    //inizializzo stati che mi servono LOCALMENTE
    const [listaMsg, setListaMsg] = useState([]);
    //gestisce l'avviso nessun msg ricevuto
    const [listaVuota, setListaVuota] = useState(true); 
    //stato in cui verranno salvate le info su un singolo messaggio creato,appeso e cliccato
    const [clickedMessage, setClickedMessage] = useState([]); 

    //stati per la PAGINATION
    const [offsetCounter, setOffsetCounter] = useState(0); //modificato solo in Pagination
    const dataLimit = 15 //modificabile anche dallo user un giorno
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0); //mi esce dalla fetch

    /* SET TOTALCOUNT: chiamata per sapere il totale dei msg => serve alla PAGINATION --->getTotalCountStored */
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId);
        myHeaders.append("x-ruolo", ruolo);

        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(url+"api/v1/messages/totalCount", requestOptions)
            .then(response => 
                response.json()
            )
            .then(result => {
                //console.log("TOTALCOUNT degli stored",result.count);
                setTotalCount(result.count);
            })
            .catch(error => console.log('error', error));
    },[listaMsg])

    //GET  lista completa dei messaggi INBOX. dependencies:tutte le cose hanno effetto sulla modifica della listamsg
    //offsetCounter: PAGINATION
    useEffect(()=>{
        var myHeaders = new Headers();
        myHeaders.append("x-access-token",  accessToken); 
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-to", userId);
        myHeaders.append("x-ruolo", ruolo);
        myHeaders.append("x-offsetinput", offsetCounter);
        myHeaders.append("x-dataLimit", dataLimit);
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(url+"api/v1/messages/stored/all", requestOptions)
        .then(response =>{
                if(response.status === 200){
                    return response.json();
                }else{
                    return;
                }
        })
        .then(result => {
            setLoading(false)

            // console.log("ricevuti", result.message.length);
            if(result.message.length !== 0){
                //console.log("stored", result.message); 
                setListaVuota(false); 
                setListaMsg(result.message);
            }else if (result.message.length === 0){
                setListaVuota(true); //metterei anche setListaMsg([]); ti servirà nella gestione dei delete
                setListaMsg([]);
            }
        })
        .catch(error => console.log('error', error));
    },[offsetCounter, renderAfterNotification]) 
    //console.log("listaMessaggi", listaMsg);
    
    return(
        <div className="fadeIn">
        <div className="listaNot">
            <div className="headListaMessages">
                    <FontAwesomeIcon icon={faUsers} id="usersIcon" /> <div className="tooltipUsers">Mittenti</div>
                    <FontAwesomeIcon icon={faClock} className="clockIconMsgs" /> <div className="tooltipClockMsgs">Data</div>
            </div>
            <br/>
            {listaVuota && <p> Nessuna conversazione archiviata!</p>}
            {loading &&<div className="lds-dual-ring"></div>}

            {listaMsg.map(msg => (
            <SingleMessage ruolo={ruolo} inboxOrSent={"inbox"} clickedMessage={clickedMessage} setClickedMessage={setClickedMessage} msg={msg}
                            key={msg.id} /* id={msg.id} subject={msg.subject} text={msg.text} flag={msg.flag} to={msg.to} from={msg.from} */ usernameFrom={msg.username} /* date={msg.createdAt} consensus={msg.consensus} type={msg.type} epoch={msg.epoch} */>
            </SingleMessage>))
            } 
        </div>
        <PaginationArrows totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={dataLimit} offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}></PaginationArrows>
        </div>
    );
}

export default StoredContainer;
