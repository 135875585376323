import React, { useState, useEffect, useContext, useRef } from "react";
import Header from "../Components/Header.js";
import "../style/ChatPage.css";

import { useHistory } from "react-router-dom";
import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import { ClickedMessageContext } from "../Context/ClickedMessageContext.js";
import { RerenderContext } from "../Context/RerenderAfterNewMsgContext.js";
import { io } from "socket.io-client";

import { faShare, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import baseUrl from '../utilities/globalVariables.js'

function ChatPage() {
    const url = baseUrl
    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId, utentiUfficio, setUtentiUfficio} = userObject
    
    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    /* la socket qui viene aperta solo quando c'è da INVIARE il messaggio. come lo ricevo? non lo ricevo. 
    Lo riceve l'header e cambiando questo stato mi forza il rerender di questo component */
    const afterNotificationObject = useContext(RerenderContext);
    const renderAfterNotification = afterNotificationObject.renderAfterNotification;// stato che uso come dep dello useEffect per renderizzare dopo l'arrivo di un nuovo messaggio
    
    const clickedMessageCont = useContext(ClickedMessageContext);//props.location.state;
    const {clickedMessage, setClickedMessage }= clickedMessageCont;
    //console.log('clicekMessage', clickedMessage)
    const containerRef = useRef(null);
    
    const [foto, setFoto] = useState([]);
    const [listaMessaggi, setListaMessaggi] = useState([]);
    const [conversazioneArchiviata, setConversazioneArchiviata] = useState(false);
    //stati per gestire la RISPOSTA ad un msg
    const [invia, setInvia] = useState(false); //serve per rirenderizzare dopo aver inviato un messaggio
    const [testo, setTesto] = useState("");
    const [answer, setAnswer] = useState(false)
    const [avviso, setAvviso] = useState("Inserisci un messaggio da inviare..");
    const [urlCliccata, setUrlCliccata]= useState('')
    const [openImage, setOpenImage]= useState(false)
    const [loading , setLoading] = useState(false)
    
    const maxFiles = 6
    const maxDimension = 10000000
    const history = useHistory();
    
    //per il render condizionale del consensus
    const ruoloController = ruolo === "moderator"?true:false;

    const altroId = clickedMessage.from===userId ? clickedMessage.to: clickedMessage.from;

    const typeMap = { Segnalazione: 1, Reclamo: 2, Suggerimento: 3, Altro: 4 };
    const clickedType = typeMap[clickedMessage.type];
    
    useEffect(()=>{
        if(accessToken===""){
            if(window.localStorage.length === 0 || window.localStorage.username===undefined || window.localStorage.accessToken===undefined){
                history.push('/');
                return
            }
            if(window.localStorage.accessToken){
                setRuolo(window.localStorage.ruolo); 
                setUsername(JSON.parse(window.localStorage.username));
                setAccessToken(JSON.parse(window.localStorage.accessToken));
                setUserId(+window.localStorage.userId); 
            } 
        }
    
        if(!clickedMessage.id){
            setClickedMessage({})
            history.push('/messaggi')
        }

        //renderizza la SideBar
        setSidebarRender(true);
    },[])
    

    useEffect(() => {
        const container = containerRef.current;
        container.scrollTop = container.scrollHeight;
    },[listaMessaggi]);

    async function fetchUtentiUfficio() {
        try {
            const requestOptions = {
                method: "GET",
                headers: {
                "x-access-token": accessToken,
                "Content-Type": "application/json",
                },
                redirect: "follow",
            };
        
            const response = await fetch(url + "api/v1/utentiUfficio", requestOptions);
            const data = await response.json();
        
            const arrayUtentiUfficio = data.utentiUfficio.map((uff) => ({
                id: uff.id,
                username: uff.username,
            }));
        
            setUtentiUfficio(arrayUtentiUfficio);
        } catch (error) {
            console.log(error);
        }
    }
    
    async function fetchChatMessages() {
        try {
            const myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-to", userId);
            myHeaders.append("x-from", altroId);
            myHeaders.append("x-subject", clickedMessage.subject);
        
            const requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };
        
            const response = await fetch(url + "api/v1/messages/chat", requestOptions);
            const result = await response.json();
        
            if (typeof result.message === "string") {
                setListaMessaggi([]);
            } else {
                setListaMessaggi(result.message);
                modifyFlagIfUnread(result.message);
            }
        
            const isConversazioneArchiviata =
                (ruolo === "user" && (result.message[0].stored === 3 || result.message[0].stored === 1)) ||
                (ruolo === "moderator" && (result.message[0].stored === 3 || result.message[0].stored === 2));
        
            setConversazioneArchiviata(isConversazioneArchiviata);
        
            if (result.message.length === 2) {
                setAnswer(true);
            } else {
                setAnswer(false);
            }
        } catch (error) {
            console.log("error", error);
        }
    }
    
    useEffect(() => {
        if (ruolo !== "user" && ruolo !== "super") {
            fetchUtentiUfficio();
        }
        fetchChatMessages();
    }, [accessToken, invia, renderAfterNotification]);
      

    //all'invio di ogni messaggio mando tramite la socket l'input al destinatario
    //è solo per INVIARE (la ricezione è gestita in HEADER)
    function handleSocket() {
        const socket = io(url);
        socket.emit("message", altroId);
        socket.emit("end");
        console.log("chiusa la socket");
    }

    //POST - manda il messaggio di risposta
    function handleSubmitReply() {
        setLoading(true)

        if (!testo&&foto.length===0) {
            //console.log("campo vuoto");
            setAvviso('Campo vuoto..!')
            setLoading(false)
            return; //esco dalla funzione e non faccio fetch
        }
        if(foto.length>maxFiles){
            setLoading(false)
            setAvviso('Numero massimo di foto superato')
            return
        }
        setAvviso("Inserisci un messaggio da inviare..")
        let consensus;
        if (clickedMessage.consensus === "SI") {
            consensus = true;
        } else {
            consensus = false;
        }

        //costruisco la fetch POST per creare nuovo msg
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        const formData = new FormData();
        //formData.append("role", ruolo); //mi serve per segnare il primo della lista answered

        formData.append("text", testo);
        formData.append("from", userId);
        formData.append("to", altroId);
        formData.append("subject", clickedMessage.subject);
        formData.append("consensus", consensus);
        formData.append("type", clickedType);
        formData.append("fromutenteufficioid", window.localStorage.idUtenteUfficio?window.localStorage.idUtenteUfficio:null); //se sono utente ufficio 

        if(answer){
            formData.append("answer", true);
        }

        if(foto){
            formData.append("mediaType", 1);
        }else{
            formData.append("mediaType", 0);
        }

        for (let i = 0; i < foto.length; i++) {
            formData.append("images", foto[i]);
            //console.log('immagini', foto)
        }

        let requestOptions = {
            method: 'POST',
            headers:myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(url+"api/v1/messages/singolo", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if(result.message){
                    //console.log(result.message);
                    setInvia(!invia);
                    setTesto("");
                    setFoto([])
                    setLoading(false)
                    setAvviso("Inserisci un messaggio da inviare..")
                }
                handleSocket();
                if(result.error){
                    setAvviso(result.error)
                }
            })
            .catch((error) => console.log("error", error));
    }

    //quando apro una conversazione con più messaggi da leggere, vengono tutti segnati come letti, modificandone il flag */ //LEGGIMESSAGGIO
    function modifyFlagIfUnread(arrayConversazione){
        //console.log("arrayConversazione", arrayConversazione);
        arrayConversazione.forEach(msg =>{
            if( msg.to === userId && msg.flag === true ){
                //console.log("Nella conversazione c'è un messaggio a cui cambiare flag")
                let myHeaders = new Headers();
                myHeaders.append("x-access-token", accessToken);
                myHeaders.append("x-msgId", msg.id);

                let requestOptions = {
                    method: "GET",
                    headers: myHeaders,
                    redirect: "follow",
                };

                fetch(url+"api/v1/messages/singolo", requestOptions)
                    .then((response) => response.text())
                    /* .then((result) => {
                        console.log('flag modificato');
                    }) */
                    .catch((error) => console.log("error", error));
                }
        })
    }
    ////// ARCHIVIA CONVERSAZIONE
    function storeConversation(){
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId); //BE VIENE VALUTATA ANCHE to=altroId e from=userId
        myHeaders.append("x-from", altroId);
        myHeaders.append("x-subject", clickedMessage.subject);
        myHeaders.append("x-ruolo", ruolo);


        let requestOptions = {
            method: "PUT",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            url+"api/v1/messages/chat",
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                //console.log(response);
                setConversazioneArchiviata(true)
            })
    }

    function unStoreConversation(){
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId); //BE VIENE VALUTATA ANCHE to=altroId e from=userId
        myHeaders.append("x-from", altroId);
        myHeaders.append("x-subject", clickedMessage.subject);
        myHeaders.append("x-ruolo", ruolo);


        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            url+"api/v1/messages/chat",
            requestOptions
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                //console.log(response);
                setConversazioneArchiviata(false)
            })
    }

    //per aggiungere immagini
    function handleInputImages(e){
        if (e.target.files.length > maxFiles) {
            //alert("Only 15 files accepted.");
            setFoto([])
            setAvviso(`Non puoi caricare più di ${maxFiles} immagini`)
        //e.preventDefault();
            return
        }
        for (let i = 0, numFiles = e.target.files.length; i < numFiles; i++) {
            const file = e.target.files[i];
            if(file.size>maxDimension){
                setFoto([])
                setAvviso(`I files non possono superare singolarmente ${maxDimension/1000000}Mb`)

                return
            }
        }
        setFoto(e.target.files)
        //setAvviso("Inserisci un messaggio da inviare..")

    }


    function apriImmagine(e){
        //console.log(e.target.src)
        setUrlCliccata(e.target.src)
        setOpenImage(true)
    }

    function takeUsernameUtenteUfficioFromId(utUfId){
        //console.log('utentiUfficio', utentiUfficio.length, utentiUfficio)
        if(!utentiUfficio[0].id){
            window.location.reload();
        }
        let utente = utentiUfficio.filter(ut => ut.id === utUfId)
        return utente[0].username 
    }

    return (
        <div className="Appp2">
            <div className="containerRight">
                <Header headImgs="true"></Header>
                <div className="containersVari">
                    <div id="headChatPage">
                        <button className="backButton" onClick={() => history.push("/messaggi")}>
                            <FontAwesomeIcon icon={faShare} />
                        </button>
                        <h2 id="chatTitle">{clickedMessage.nickname}</h2>
                        <div className="storeMessageButton" > 
                            {!conversazioneArchiviata &&<div className="blueButtons archiviaButton" onClick={()=>storeConversation()}>ARCHIVIA CONVERSAZIONE </div>}
                            {conversazioneArchiviata &&<div className="blueButtons archiviaButton" onClick={()=>unStoreConversation()}>RIPRISTINA CONVERSAZIONE </div>}
                        </div> 
                    </div>
                    
                    <div id="containerChatOuter">
                        {ruoloController && (
                            <span id="consensusChatPage"> <b>Consenso fornito: </b>{clickedMessage.consensus}
                            </span>
                        )}
                        <div className={`immagineApertaDiv ${openImage?"":"chiusa"}`} onClick={()=>setOpenImage(false)} >
                            <img src={`${urlCliccata}`}></img>
                        </div>
                        <span id="subjectChat"> <b>Oggetto: </b>"{clickedMessage.subject}"
                        </span>
                        <span id="subjectChat"> <b>Tipo: </b>{clickedMessage.type}
                        </span>
                        <div id="containerChatInner" ref={containerRef}>
                            {listaMessaggi?.map(msg => {
                                //formatto orario creazione
                                let epoch = msg.epoch;
                                let data = new Date(+epoch).toLocaleString(
                                    "it-IT"
                                );
                                let orario = data.split(", ")[1].split(":");
                                orario = orario[0] + ":" + orario[1];
                                data = data.split(", ")[0];
                                if (msg.to === msg.from) {
                                    return (
                                            <div   className="messageSent"  key={msg.id}  >
                                                <div className="testoIo">   
                                                <p dangerouslySetInnerHTML={{__html: msg.text}}></p>
                                                {msg.mediaUrl?msg.mediaUrl.map((pic,i)=> <div key={i+'b'} ><embed src={baseUrl+pic} style={{width:'20vw', cursor: 'pointer'}} onClick={(e)=>apriImmagine(e)}></embed></div>):null}
                                                <div className="dataEora">  
                                                    {(ruolo!=='user'&& ruolo !== 'super')&&msg.fromUtenteUfficioId!== null&&<span>{takeUsernameUtenteUfficioFromId(msg.fromUtenteUfficioId)} - </span>}
                                                    {data}-{orario}
                                                </div>
                                                </div>
                                            </div>
                                    );
                                } else if (msg.to===userId) {
                                    return (
                                        <div className="messageInbox" key={msg.id} >
                                            <div className="testoLui">
                                                <p dangerouslySetInnerHTML={{__html: msg.text}}></p>
                                                {msg.mediaUrl?msg.mediaUrl.map((pic,i)=> <div key={i+'c'} ><embed src={baseUrl+pic} style={{width:'20vw', cursor: 'pointer'}} onClick={(e)=>apriImmagine(e)}></embed></div>):null}
                                                <div className="dataEora"> 
                                                    {(ruolo!=='user'&& ruolo !== 'super')&&msg.fromUtenteUfficioId!== null&&<span>{takeUsernameUtenteUfficioFromId(msg.fromUtenteUfficioId)} - </span>}
                                                    {data}-{orario}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else if (msg.from===userId) {
                                    return (
                                        <div className="messageSent"  key={msg.id}  >
                                            <div className="testoIo">
                                                <p dangerouslySetInnerHTML={{__html: msg.text}}></p>
                                                {msg.mediaUrl?msg.mediaUrl.map((pic,i)=> <div key={i+'d'} ><embed  src={baseUrl+pic} style={{width:'20vw', cursor: 'pointer'}} onClick={(e)=>apriImmagine(e)}></embed ></div>):null}
                                                <div className="dataEora">
                                                    {(ruolo!=='user'&& ruolo !== 'super')&&msg.fromUtenteUfficioId!== null&&<span>{takeUsernameUtenteUfficioFromId(msg.fromUtenteUfficioId)} - </span>}
                                                    {data}-{orario}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return(<p key={msg.id}></p>)
                                }
                            })}
                        {loading &&<div className="lds-dual-ring" ></div>}
                        </div>
                        {!loading&&<div id="replyContainer">
                            <textarea
                                id="risposta"
                                aria-label="area in cui inserire una risposta"
                                value={testo}
                                onChange={(e) => setTesto(e.target.value)}
                                placeholder={avviso}
                            ></textarea>
                            <div  className="replyChatButtonAddFiles">
                                <label htmlFor='imagesInput' ><FontAwesomeIcon icon={faPlus} /> </label>
                                <input type="file" id="imagesInput" accept="image/*" name="images" aria-label="Allega foto" style={{display:'none'}} multiple onChange={(e)=>handleInputImages(e)}></input>
                                {foto.length>0 &&<div  className="fileSelezionatiText" >Selezionati:{foto.length}</div>}
                            </div>
                            <button  className="replyChatButton"  onClick={() => handleSubmitReply()}  >
                                <FontAwesomeIcon icon={faShare} />
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatPage;
