import hermesEnvironment from './environment'

const AzureActiveDirectoryAppClientId = '17e2d586-1db6-4524-8ee9-eced92d2ea83'; //appId
const AzureActiveDirectoryAppClientIdDEV = '6c424964-2547-436c-9c2a-3656ec0cc38c';

const production = {
    appId: AzureActiveDirectoryAppClientId,
    tenant: '51d4e170-e505-4a0d-8885-aaf538e05e75',
    redirect: 'https://hermes.avrgroup.it/'
}

const development = {
    appId: AzureActiveDirectoryAppClientIdDEV,
    tenant: '5bc4a8d1-5c1e-4864-8d6c-f2382c1ff954',
    redirect: 'http://localhost:3001/'
}

const azureEnvironment = hermesEnvironment==='development'?development:production


export default azureEnvironment;