import React from "react";
import { useContext, useEffect } from "react";
import {useHistory} from "react-router-dom";

import Header from "../Components/Header.js";
import ContainerNewMessage from "../Containers/ContainerNewMessage.js";
import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";

function NewMessagePage() {
  const userObject = useContext(UserContext);
  const { setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

  const sideBarContext = useContext(SidebarRenderContext);
  const setSidebarRender = sideBarContext.setSidebarRender;

   //renderizza la SideBar
  useEffect(()=>{
    setSidebarRender(true);
  })
  
  let history = useHistory();
  if(window.localStorage.length === 0 || window.localStorage.username===undefined){
    history.push('/');    
  }
  if(accessToken === ""){
    if(window.localStorage.accessToken){
      setRuolo(window.localStorage.ruolo); //sono già stringhe
      setUsername(JSON.parse(window.localStorage.username));
      setAccessToken(JSON.parse(window.localStorage.accessToken));
      setUserId(window.localStorage.userId); //sono già stringhe
    } else {
        history.push ("/")
    }
  }
  
  return (
    <div className="Appp2">
      <div className="containerRight">
        <Header headImgs="true"></Header>
        <div className="containersVari">
          <ContainerNewMessage></ContainerNewMessage>
        </div>
      </div>
    </div>
  );
}

export default NewMessagePage;
