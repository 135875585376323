import "../style/ContainerNewNotice.css"
import "../style/WhiteButtons.css";
import {useState, useContext} from 'react';
import { useHistory,Link} from "react-router-dom";
import {UserContext} from "../Context/UserContext.js";

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'

function ContainerNewNotice(){
    //inizializzo stati LOCALI
    const [title, setTitle]= useState('');
    const [text,setText]= useState('');
    const [notEmpty, setNotEmpty]= useState(false);

    const userObject = useContext(UserContext);
    const {userId, accessToken} = userObject

    var url=baseUrl

    //quando clicco su Fatto reindirizzo alla bachecaPage
    let history = useHistory();

    function handleSubmit(){ //on submit farò una POST per la creazione
        if(!title || ! text){
            console.log("campi vuoti");
            setNotEmpty(true)
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);
        myHeaders.append("x-utenteufficio-id",  window.localStorage.idUtenteUfficio?window.localStorage.idUtenteUfficio:null);


        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("title", title);
        urlencoded.append("text", text);
        urlencoded.append("from", userId);

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(url +"api/v1/notices/single", requestOptions)
        .then(response => response.text())
        .then(result => {
            //console.log(result)
            history.push('/bacheca');
        })
        .catch(error => console.log('error', error));
            }
    //console.log(text);
    return ( 
        <div className="containerNewNot">
            < div className="headNewNot">
                <h2 id="newNotTitle"> NUOVO AVVISO</h2>
            </div> 
            <div className="bodyNewNot">
                <h4>Titolo (Topic)</h4>
                <input className="inputTopic" type="text" value={title} maxLength="75" onChange={e=> setTitle(e.target.value)}></input>
                
                <h4>Testo</h4>
                <textarea value={text} onChange={e=> setText(e.target.value)}></textarea> <br/>
                <div className="newNotButtonContainer">
                    <button className="blueButtons" id="newNotLeftButton" type="submit" onClick={handleSubmit}><FontAwesomeIcon icon={faCheck} style={{marginRight:'1vw'}}></FontAwesomeIcon>PUBBLICA</button>
                    <Link to="bacheca"><button className="blueButtons" id="newNotRightButton"><FontAwesomeIcon icon={faTimes} style={{marginRight:'1vw'}}></FontAwesomeIcon>ANNULLA</button></Link>
                </div>
                {notEmpty && <p className="errorP">Entrambi i campi devono essere non vuoti.</p>}   

            </div>
        </div>
    );
}

export default ContainerNewNotice; 