import React, { useState, useEffect, useContext } from "react";
import Header from "../Components/Header.js";
import "../style/ChatPage.css";

import { useHistory } from "react-router-dom";
import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import { ClickedMessageContext } from "../Context/ClickedMessageContext.js";
import { RerenderContext } from "../Context/RerenderAfterNewMsgContext.js";

import { faShare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import baseUrl from '../utilities/globalVariables.js'

function ChatPage() {
    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }
    const url=baseUrl

    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId, utentiUfficio, setUtentiUfficio} = userObject

    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;

    const afterNotificationObject = useContext(RerenderContext);
    const renderAfterNotification = afterNotificationObject.renderAfterNotification;// stato che uso come dep dello useEffect per renderizzare dopo l'arrivo di un nuovo messaggio

    const clickedMessageCont = useContext(ClickedMessageContext);//props.location.state;
    const {clickedMessage, setClickedMessage} = clickedMessageCont
    //console.log(clickedMessage);
    if(!clickedMessage.id){
        setClickedMessage({})
        history.push('/messaggiSuperadmin')
    }
    const subject = clickedMessage.subject; //oggetto msg

    const [listaMessaggi, setListaMessaggi] = useState([]);

    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }

    useEffect(()=>{
        //renderizza la SideBar
        setSidebarRender(true);
        //funzione che dopo la creazione del div gli dice di partire lo scroll dal bottom invece che dal top
        document.getElementById("containerChatInnerSuperadmin").scrollTop = document.getElementById("containerChatInnerSuperadmin").scrollHeight;
    })

    //per il render condizionale del consensus
    let ruoloController = false;
    if (ruolo === "moderator") {
        ruoloController = true;
    }
    let altroId;
    if (clickedMessage.from === userId) {
        //significa che ho cliccato un mes tra i SENT
        altroId = clickedMessage.to;
    } else if (clickedMessage.to === userId) {
        //significa che ho cliccato un mes tra i INBOX
        altroId = clickedMessage.from;
    }

    //GET - fetch che pesca tutti i msgs relativi alla conversazione(per subject) ///MOSTRA CONVERSAZIONE
    useEffect(() => {
            const requestOptions = {
                method: "GET",
                headers: { 
                    "x-access-token": accessToken, 
                    "Content-Type": "application/json",
                    //"x.user-id": userId  //DA AGGIUNGERE SE SI VUOLE UN UNICO UFFICIO DI COMPETENZA
                },
                redirect: 'follow'
            };
    
            fetch(url + "api/v1/utentiUfficio", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                console.log('HEY', response);
                let arrayUtentiUfficio = [];
                response.utentiUfficio.forEach(uff=>{
                    arrayUtentiUfficio.push({ id:uff.id, username:uff.username })
                })
                setUtentiUfficio(arrayUtentiUfficio)
                /* window.localStorage.setItem(
                    "arrayUtentiUfficio",
                    JSON.stringify(arrayUtentiUfficio)) */
            })
            .catch((err) => {
                console.log(err)
            }) 
        const myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", clickedMessage.to); //BE VIENE VALUTATA ANCHE toe from invertiti
        myHeaders.append("x-from", clickedMessage.from);
        myHeaders.append("x-subject", subject);
        const requestOptions2 = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch(
            url+"api/v1/messages/chat",
            requestOptions2
        )
            .then((response) => {
                return response.json();
            })
            .then((result) => {
                //console.log("chat complessiva", result.message);
                if (typeof result.message === "string") {
                    setListaMessaggi([]);
                } else {
                    setListaMessaggi(result.message);
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [accessToken, renderAfterNotification]);

    const [urlCliccata, setUrlCliccata]= useState('')
    const [openImage, setOpenImage]= useState(false)

    function apriImmagine(e){
        //console.log(e.target.src)
        setUrlCliccata(e.target.src)
        setOpenImage(true)
    }

    function takeUsernameUtenteUfficioFromId(utUfId){
        if(!utentiUfficio[0].id){
            window.location.reload();
        }
        let utente = utentiUfficio.filter(ut => ut.id === utUfId)
        return utente[0].username
    }

    return (
        <div className="Appp2">
            <div className="containerRight">
                <Header headImgs="true"></Header>
                <div className="containersVari">
                    <div id="headChatPage">
                        <button className="backButton" onClick={() => history.push("/messaggiSuperadmin")}>
                            <FontAwesomeIcon icon={faShare} />
                        </button>
                        <h2 id="chatTitle">Da <b>{clickedMessage.usernameFrom}</b>  a <b>{clickedMessage.usernameTo} </b></h2>
                    </div>
                    
                    <div id="containerChatOuter">
                        <span id="consensusChatPage"> <b>Consenso fornito: </b>{clickedMessage.consensus}
                        </span>
                        <div className={`immagineApertaDiv ${openImage?"":"chiusa"}`} onClick={()=>setOpenImage(false)} >
                            <img alt="immagine inviata in chat" src={`${urlCliccata}`}></img>
                        </div>
                        <span id="subjectChat"> <b>Oggetto: </b>"{clickedMessage.subject}"
                        </span>
                        <span id="subjectChat"> <b>Tipo: </b>{clickedMessage.type}
                        </span>
                        <div id="containerChatInnerSuperadmin">
                            {listaMessaggi.map((msg) => {
                                //console.log(msg)
                                //formatto orario creazione
                                let epoch = msg.epoch;
                                let data = new Date(+epoch).toLocaleString(
                                    "it-IT"
                                );
                                let orario = data.split(", ")[1].split(":");
                                orario = orario[0] + ":" + orario[1];
                                data = data.split(", ")[0];
                                if (msg.to === clickedMessage.to) {
                                    return (
                                        <div className="messageInbox" key={msg.id} >
                                            <div className="testoLui">
                                                <p dangerouslySetInnerHTML={{__html: msg.text}}></p>
                                                {msg.mediaUrl?msg.mediaUrl.map((pic,i)=> <div key={i+'c'} ><embed src={baseUrl+pic} style={{width:'20vw', cursor: 'pointer'}} onClick={(e)=>apriImmagine(e)}></embed></div>):null}
                                                <div className="dataEora"> 
                                                    {data}-{orario}
                                                </div>

                                            </div>
                                        </div>
                                    );
                                } else if (msg.from === clickedMessage.to) {
                                    return (
                                        <div   className="messageSent"  key={msg.id * 10}  >
                                            <div className="testoIo">
                                                <p dangerouslySetInnerHTML={{__html: msg.text}}></p>
                                                {msg.mediaUrl?msg.mediaUrl.map((pic,i)=> <div key={i+'d'} ><embed src={baseUrl+pic} style={{width:'20vw', cursor: 'pointer'}} onClick={(e)=>apriImmagine(e)}></embed></div>):null}
                                                <div className="dataEora">
                                                {msg.fromUtenteUfficioId&&<span>{takeUsernameUtenteUfficioFromId(msg.fromUtenteUfficioId)} - </span>}
                                                    {data}-{orario}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChatPage;
