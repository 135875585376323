import React from 'react';
import '../style/ModaleConferme.css'



function ModaleDiConferme(props){

    const testo = props.testo; //stringa
    const setModale = props.setModale;
    const funzione = props.funzione;

    //console.log(props)
    return(
        <div className='confermaEliminazioneBgModal' onClick={(e)=>{if(e.target === e.currentTarget){ e.stopPropagation(); setModale(false)}}}>
            <div className='confermaEliminazioneDivModal'>
                <p>{testo}</p>
                <div style={{display:'flex', justifyContent:'center', margin:'2%'}}>
                    <div style={{margin:'4%', padding:'1vh 2vw'}} className='blueButtons' onClick={()=>funzione()}>SI, PROCEDI</div>
                    <div style={{ margin:'4%', padding:'1vh 2vw'}} className='blueButtons' onClick={()=>setModale(false)}>NO, ANNULLA</div>
                </div>
            </div>
        </div>
    )
}
export default ModaleDiConferme