
import "../style/MenuBar2.css";

import React from 'react'
import logo from "../imgs/logosenzascritta.png";

import {useContext} from 'react';
import {SideMenuContext} from "../Context/SideMenuContext.js";
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";

import { NavLink } from 'react-router-dom';
import { UserContext} from "../Context/UserContext.js";

function SideBar() {
    const userObject = useContext(UserContext);
    const ruolo =userObject.ruolo;
    
    const sideMenuContext =  useContext(SideMenuContext);
    const openSidemenu = sideMenuContext.openSidemenu;
    const setOpenSidemenu = sideMenuContext.setOpenSidemenu;

    const sideBarContext = useContext(SidebarRenderContext);
    const sidebarRender= sideBarContext.sidebarRender;

//Nella parte mobile, la sidebar diventa fullscreen quindi si apre e chiude (cambiando classe + media query in CSS) al click del burger nell'header in cui viene cambiato lo stato openSideMenu
    var classeDiv = "sidebar";
    if(openSidemenu){
        classeDiv = "sidebar open";
    }else{
        classeDiv = "sidebar";
    }
//per il render condizionale di alcuni bottoni
    var userController= false;
    var adminController= false;
    var superController = false;


    if(ruolo==='user'){ userController=true}
    if(ruolo==='admin'){ adminController=true}
    if(ruolo==='super'){ 
        adminController=true
        superController=true
    }


    return (
        <>
            {!sidebarRender && <div></div>}
            {sidebarRender && <div className = {classeDiv} onClick={()=>setOpenSidemenu(false)} >
                <div id="topDivSidebar"> 
                    <p id="titoloSidebar">hermès</p>
                    <img id ="logoPrincipale" src={logo} alt="logo di hermès, profilo di donna che sussurra" /> 
                </div>

                <div className="sideMenu" >
                    <p ><NavLink  to="/bacheca"                                         activeClassName="active">Bacheca            </NavLink ></p>
                    {!userController&& <p ><NavLink  to="/newnot"                       activeClassName="active">Nuovo Avviso       </NavLink ></p>}
                    {!adminController&&<p ><NavLink  to="/messaggi"                     activeClassName="active">Messaggi           </NavLink > </p>}
                    {superController&&<p ><NavLink   to="/messaggiSuperadmin"           activeClassName="active">Messaggi           </NavLink > </p>}
                    {!adminController&&<p ><NavLink  to="/newmsg"                       activeClassName="active">Nuovo Messaggio    </NavLink ></p>}
                    {adminController&& <p ><NavLink  to="/gestione"                     activeClassName="active">Gestione Utenti    </NavLink ></p>}
                    {adminController&& <p ><NavLink  to="/uffici"                       activeClassName="active">Creazione Uffici   </NavLink ></p>}
                    {superController&& <p ><NavLink  to="/stats"                        activeClassName="active">Statistiche        </NavLink ></p>}
                    {(userController||superController)&& <p><NavLink  to="/modello231"  activeClassName="active">Modello 231        </NavLink ></p>}
                    {superController&& <p ><NavLink  to="/anonime"                      activeClassName="active">Segnalazioni Esterne  </NavLink ></p>}

                </div>
                
                <div className="privacyDiv" > 
                    <a href="https://www.avrgroup.it/azienda/modello-231/privacy-policy" target="_blank" rel="noreferrer">Privacy  &amp;  Legal</a>
                </div>
            </div>}
        </>
    );
}
export default SideBar;
