import React, {useState, createContext} from 'react';

export const SideMenuContext = createContext();

export const SideMenuProvider = props =>{

    const [openSidemenu, setOpenSidemenu] = useState(false)

    var sideMenuContext = {
        openSidemenu: openSidemenu,
        setOpenSidemenu: setOpenSidemenu
    };

    return(
        <SideMenuContext.Provider value = { sideMenuContext }>
            {props.children}
        </SideMenuContext.Provider>
    );
}
/*
    Questo CONTEXT serve Solo x il MOBILE!!!!!!!!!! (nello screen la sidebar resta aperta, non va aperta o chiusa)
    Se in Header viene cliccato il menu (hamburgerino):
    onClick={()=>setOpenSidemenu(!openSidemenu)}
    Funziona solo nella parte mobile e viene richiamato nella sidebar
*/