import React from 'react';
import {useState, useEffect, useContext} from 'react';
import "../style/MessaggiPopups.css";

import {UserContext} from "../Context/UserContext.js";

import {RerenderContext} from "../Context/RerenderAfterNewMsgContext.js";
import SingleMessage from "../Components/SingleMessage.js";
import PaginationArrows from "../Components/ArrowsPagination";
import { Link } from 'react-router-dom';

import { faClock} from "@fortawesome/free-regular-svg-icons";
import { faUsers} from "@fortawesome/free-solid-svg-icons";
import { faSearch} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'

function InboxContainer(){
  const userObject = useContext(UserContext);
  const {userId, ruolo, accessToken} = userObject

  const afterNotificationObject = useContext(RerenderContext);
  const renderAfterNotification = afterNotificationObject.renderAfterNotification; //stato che uso come dep dello useEffect per renderizzare dopo l'arrivo di una notifica
  
  const url=baseUrl

  //stato per loader
  const [loading , setLoading] = useState(true)

  const [listaMsg, setListaMsg] = useState([]);
  //gestisce l'avviso nessun msg ricevuto
  const [listaVuota, setListaVuota] = useState(true); 

  //stati per la PAGINATION
  const [offsetCounter, setOffsetCounter] = useState(0); //modificato solo in Pagination
  const dataLimit = 6; //modificabile anche dallo user un giorno
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0); //mi esce dalla fetch

  /* SET TOTALCOUNT: chiamata per sapere il totale dei msg => serve alla PAGINATION --->getTotalCountInboxOrSent */
  useEffect(() => {
    if(accessToken){

    const getTotalCount = async () => {
      try {
        const myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId);
        myHeaders.append("x-ruolo", ruolo);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch(url + "api/v1/messages/totalCount", requestOptions);
        const result = await response.json();
        setTotalCount(result.count);
      } catch (error) {
        console.log('error', error);
      }
    };

    getTotalCount();
  }
  }, [listaMsg, accessToken, userId, ruolo]);

  //GET  lista completa dei messaggi INBOX. dependencies:tutte le cose hanno effetto sulla modifica della listamsg
  //offsetCounter: PAGINATION
  useEffect(() => {
    if(accessToken){
      const getInboxMessages = async () => {
        try {
          const myHeaders = new Headers();
          myHeaders.append("x-access-token", accessToken);
          myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
          myHeaders.append("x-to", userId);
          myHeaders.append("x-ruolo", ruolo);
          myHeaders.append("x-offsetinput", offsetCounter);
          myHeaders.append("x-dataLimit", dataLimit);

          const requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          const response = await fetch(url + "api/v1/messages/inbox/all", requestOptions);
          if (response.status === 200) {
            const result = await response.json();
            setLoading(false);
            setListaVuota(result.message.length === 0);
            setListaMsg(result.message);
          }
        } catch (error) {
          console.log('error', error);
        }
      };
      getInboxMessages();
    }
  }, [offsetCounter, renderAfterNotification, accessToken, userId, ruolo]);

  
  return(
    <div className="fadeIn">
      <Link to={{pathname:'/search', state:{inboxOrSent: "inbox"}}} > <button className="blueButtons cerca" aria-label="Ricerca tra ricevuti"><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> </button> </Link >
      
      <div className="listaNot">
        <div className="headListaMessages">
                <FontAwesomeIcon icon={faUsers} id="usersIcon" /> <div className="tooltipUsers">Mittenti</div>
                <FontAwesomeIcon icon={faClock} className="clockIconMsgs" /> <div className="tooltipClockMsgs">Data</div>
        </div>
        <br/>
        {listaVuota && <p> Nessun messaggio ricevuto!</p>}
        {loading &&<div className="lds-dual-ring"></div>}

        {listaMsg.map(msg => (
          <SingleMessage ruolo={ruolo} inboxOrSent={"inbox"} msg={msg} key={msg.id} usernameFrom={msg.username}
                        /*  id={msg.id} subject={msg.subject} text={msg.text} flag={msg.flag} to={msg.to} from={msg.from} usernameFrom={msg.username} date={msg.createdAt} consensus={msg.consensus} type={msg.type} epoch={msg.epoch} */>
          </SingleMessage>))
        } 
      </div>
      <PaginationArrows totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={dataLimit} offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}></PaginationArrows>
    </div>
  );
}

export default InboxContainer;
