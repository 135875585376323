import React, { useEffect } from "react"; //il pacchetto React viene importato per gestire l’elaborazione JSX
import { useState, useContext } from "react";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import LoginForm from "../Components/LoginForm.js";
import RegisterForm from "../Components/RegisterForm.js";

import logo from "../imgs/logoWhite.png";
import "../style/HomePage.css";

function HomePage() {
    //per non renderizzare  la sidebar
    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    
    const [loginPage, setLoginPage] = useState(true); //stato per cui passo da login a register
    
    //stati che uso per far spostare la barra blu (vengono attivati da dentro login e registerform )
    const [bodyLoginClassName, setBodyLoginClassName] = useState("left");
    const [bodyLoginClassName2, setBodyLoginClassName2] = useState("right2");

    useEffect(()=>{
        setSidebarRender(false);
    },[])

    return (
        <div className="">
            <div id="bodyLoginLeft" className={bodyLoginClassName}>   
                <img id="logoPrincipaleLogin" src={logo} alt="immagine Logo"/>
            </div>
            <div id="bodyLoginRight" className={bodyLoginClassName2}>
                {loginPage && (
                    <LoginForm  setLoginPage={setLoginPage} setBodyLoginClassName={setBodyLoginClassName} setBodyLoginClassName2={setBodyLoginClassName2} >
                    </LoginForm>
                )}
                {!loginPage && (
                    <RegisterForm  setLoginPage={setLoginPage}  setBodyLoginClassName={setBodyLoginClassName}  setBodyLoginClassName2={setBodyLoginClassName2} >
                    </RegisterForm>
                )}
            </div>
        </div> 
    );
}

export default HomePage;
