import "../style/Header.css";
import { useState, useContext, useEffect } from "react";
import { Link} from "react-router-dom";
import Modal from "react-modal";

import { UserContext } from "../Context/UserContext.js";
import { io } from "socket.io-client";
import { RerenderContext } from "../Context/RerenderAfterNewMsgContext.js";
import { SideMenuContext } from "../Context/SideMenuContext.js";

import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../imgs/logoBlu.png";
import baseUrl from '../utilities/globalVariables.js'
import {useHistory} from "react-router-dom";
import ModaleDiConferme from './ModaleDiConferme.js';
import SsoButton from "./SsoButton";



function Header(props) {
    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, username, setUsername, accessToken, setAccessToken, setUserId} = userObject

    //questo context contine uno stato che mi permette di forzare il rendering degli inbox quando ne arriva uno nuovo
    const afterNotificationObject = useContext(RerenderContext);
    const {renderAfterNotification, setRenderAfterNotification} = afterNotificationObject;

    //gestione sidebar solo per la PARTE MOBILE
    const sideMenuContext =  useContext(SideMenuContext);
    const {openSidemenu, setOpenSidemenu} = sideMenuContext;

    const [customizedColor, setCustomizedColor] = useState("#f9f9f9"); /**serve per colorare il pallino della bell */
    const [userMenu, setUserMenu] = useState(false);
    const [cursorBell, setCursorBell] = useState("default");
    const [openPopUp, setOpenPopUp] = useState(); //PopUp con le informazioni su un singolo msg
    const [messaggiDaLeggere, setMessaggiDaLeggere] = useState([]);

    const url = baseUrl;

    /**per gestire l'immagine utente  */
    var userController = false;
    var moderatorController = false;
    var adminController = false;
    var superController = false;

    let usernameUtenteUfficio = '';
    if(window.localStorage.usernameUtenteUfficio){ usernameUtenteUfficio = window.localStorage.usernameUtenteUfficio.split('"')[1]}
    
    /**Modali per cambio ufficioId */
    const [inserisciPasswordModal, setInserisciPasswordModal] = useState(false)
    const [cambioUfficioModal, setCambioUfficioModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [listaUffici, setListaUffici] = useState([])
    const [ufficioId, setUfficioId] = useState('')
    const [password, setPassword] = useState('')
    
    /** component per la conferma di sospensione  */
    const [modale1, setModale1] = useState(false) 
    
    /**Modali per eliminazione account*/
    const [inserisciPasswordToDestroyModal, setInserisciPasswordToDestroyModal] = useState(false)
    const [confermaEliminazioneModal, setConfermaEliminazioneModal] = useState(false)
    const [passwordShown, setPasswordShown] = useState(false);

    let history = useHistory();

    if(ruolo==='user'){ userController=true}
    if(ruolo==='moderator'){ moderatorController=true}
    if(ruolo==='admin'){ adminController=true}
    if(ruolo==='super'){ superController=true}

    function capitalize(string) {
        if (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } else {
            return;
        }
    }
    var ruoloCapitalized = capitalize(ruolo);

    //stile per i Modals
    const customStylePopup = {
        overlay: {
        backgroundColor: "rgb(255 255 255 / 0%)",
        },
    };
    const modalMenuStyle = {
        overlay: {
        backgroundColor: "rgb(255 255 255 / 0%)",
        },
    };

    var favicon = document.getElementById('favicon');
    favicon.href = "https://hermes.avrgroup.it/logoWhite.png";
    document.title = `Hermès`;
    var vecchioRef= favicon.href ;
    var faviconSize = 16;
    var canvas = document.createElement('canvas');
    canvas.width = faviconSize;
    canvas.height = faviconSize;

    var img = document.createElement('img');
    img.src = vecchioRef;


    function showNewNotify(counter){
        //console.log("show", counterNotifiche)
        setCustomizedColor("#0077bc");
        setCursorBell("pointer");
        document.title = `Hermès - ${counter}` ;
        //disegnaPallino(counter);
        
    }

    /* setto COUNTER e setto LISTADALEGGERE */
    function callAPILastInbox() {
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-to", userId);

        var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
        };

        fetch(
        url+"api/v1/messages/singolo/anteprima",
        requestOptions
        ) //LEGGILASTINBOX
        .then((response) => response.json())
        .then((result) => {
            if (result.message.length !== 0) {
                //console.log("Ci sono msgs inbox con flag true");
                setMessaggiDaLeggere(result.message);
                showNewNotify(result.message.length); 
                handleIfStored(result.message);
            } 
            else {
               // console.log("Non ci sono msgs inbox con flag true");
                setMessaggiDaLeggere([]);
                //eliminaPallino();
                document.title = `Hermès`
            }
        })
        .catch((error) => console.log("error", error));
    }

    /* cliccando sulla campana notifiche si apre il popup di visualizzazione anteprima messaggi inbox che non sono stati letti*/
    function onBellClick() {
        if (customizedColor === "#0077bc") {
        setOpenPopUp(true);
        document.title = `Hermès`
        //eliminaPallino(); 
        }
    }

    function handleClosePopUp() {
        setOpenPopUp(false);
        setCustomizedColor("#f9f9f9");
        setCursorBell("default");
        //eliminaPallino();
        /* document.title = "Hermès"; */
    }

    /* pulisco lo UserContext dopo che viene cliccato logout e genero un nuovo nickname*/
    function cleanAfterLogout() {
        localStorage.clear();
        const nickname = "User" + Math.random().toString().substring(2, 8);
        setRuolo("user");
        setUsername(nickname);
        setAccessToken("");
        setUserId("");
    }
     //per quando premo enter  a fine inserimento pwd
    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            callForLogin();
        }
    };

    const handleKeypress2 = (e) => {
        if (e.key === "Enter") {
            eliminaAccount();
        }
    };

    //se ci sono nuovi messaggi ricevuti, appartenenti ad una conversazione storata dal ricevente, 
    //la conversazione viene in automatico ripristinata per poter essere visualizzata in inbox
    function handleIfStored(resultMessaggiNuovi){
        resultMessaggiNuovi.forEach((mess)=>{
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-to", mess.to); //BE VIENE VALUTATA ANCHE to=altroId e from=userId
            myHeaders.append("x-from", mess.from);
            myHeaders.append("x-subject", mess.subject);
            myHeaders.append("x-ruolo", ruolo);
    
    
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                redirect: "follow",
            };
    
            fetch(
                url+"api/v1/messages/chat",
                requestOptions
            )
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    //console.log("risposta fetch per ripristinare conversazioni che erano archiviate",response);
                })
                .catch((error) => console.log("error", error));
        })
    }

    /*ONMOUNT/UPDATE: apertura SOCKET ricezione ------all'apertura del component viene aperta una connessione che permette di ricevere in real time il messaggio ricevuto
            se viene ricevuto un msg mentre si è online, si accende il pallino e la campana diventa cliccabile*/
    useEffect(() => {
        /*AL PRIMO ACCESSO voglio che venga fatta una fetch agli inbox che mi faccia sapere se ci sono dei msgs da leggere ricevuti 
        mentre ero offline*/
        if (window.localStorage["firstAccess"] === "true") {
            window.localStorage["firstAccess"] = false;
            callAPILastInbox();
        }
        const socket = io(url);
        socket.on(userId, (arg) => {
            setRenderAfterNotification(!renderAfterNotification);
            callAPILastInbox();
            });
        return () => socket.emit("end"); //on Unmount chiude la socket
    },[accessToken, renderAfterNotification]);

    /* ELIMINA ACOOUNT */
    function eliminaAccount(saml){
        /** la password viene mandata BE se è corretta elimino utenteUfficio */
        if(window.localStorage.usernameUtenteUfficio){
            console.log('elimino utenteufficio',saml)
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            saml!==null?myHeaders.append("x-samlemail", saml):myHeaders.append("x-password", password);
            myHeaders.append("x-utenteufficio-id", window.localStorage.idUtenteUfficio);

            let requestOptions = {
                method: "DELETE",
                headers: myHeaders,
                redirect: "follow",
            };
    
            fetch( url+"api/v1/utentiUfficio", requestOptions )
            .then(response=> response.json())
            .then(result=>{
                //console.log(result)
                if(result.message){
                    //torni al login 
                    //console.log(result.message)
                    setErrorMessage('')
                    localStorage.clear();
                    history.push('/')
                }
                if(result.error){
                    //displayError
                    setErrorMessage(result.error);
                    //console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
        }else{
            /** la password viene mandata BE se è corretta elimino user */
            console.log('elimino utente normale', userId)

            let myHeaders = new Headers();
                myHeaders.append("x-access-token", accessToken);
                myHeaders.append("x-user-id", userId);
                myHeaders.append("x-password", password);
    
                let requestOptions = {
                    method: "DELETE",
                    headers: myHeaders,
                    redirect: "follow",
                };
        
                fetch( url+"api/v1/users", requestOptions )
                .then(response=> response.json())
                .then(result=>{
                    //console.log(result)
                    if(result.message){
                        //torni al login 
                        //console.log(result.message)
                        setErrorMessage('')
                        localStorage.clear();
                        history.push('/')
                    }
                    if(result.error){
                        //displayError
                        setErrorMessage(result.error);
                        //console.log(result.error)
                    }
                })
            .catch(error => console.log('error', error));
        }
    }

    /** queste due funzioni servono per cambiare ufficio: in quanto utenteUfficio ha bisogno di un login in due fasi */
    function callForLogin(){
        //Controllo FE per l'inserimento della password: così non faccio richieste inutili al BE
        if (!password || password === "" ) {
            setErrorMessage(`Inserisci una password!`);
            //setDisplayError(true);
            return;
        }
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: usernameUtenteUfficio, password: password }),
        };

        fetch(url + "api/v1/auth/signin", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //console.log("data", data);
                if (data.error) {
                    //se c'è stato un errore lei è non vuota.
                    setErrorMessage(data.error);
                    //setInserisciPasswordModal(false)
                    return;
                }
                if(data.uffici){
                    //console.log(data.uffici)
                    setErrorMessage('');
                    setListaUffici(data.uffici);
                    setInserisciPasswordModal(false)
                    setCambioUfficioModal(true)
                    //QUI si apre UN MODALE CHE FA SCEGLIERE CON QUALE UFFICIO si vuole loggare
                    return
                }
                if (data.idUtenteUfficio){
                    //ha un solo ufficio ma ci ha provato lo stesso
                    //console.log('ciao')
                    setErrorMessage(`Questo è l'unico ufficio a cui sei associatə!`);
                    setPassword('')
                    setTimeout(function () {
                        setInserisciPasswordModal(false)
                        setErrorMessage(``)
                    }, 2000);

                }
            });
    };

    function callForLogin2(){

        //Controllo FE per l'inserimento della password: così non faccio richieste inutili al BE
        if ( ufficioId==='') {
            setErrorMessage(`Seleziona l'ufficio!`);
            return;
        }
        //console.log('parte la 2')
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ username: usernameUtenteUfficio, password: password, ufficioId: ufficioId }),
        };
    
        fetch(url + "api/v1/auth/signin", requestOptions)
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            //console.log("data", data);
            if (data.error) {
                //se c'è stato un errore lei è non vuota.
                setErrorMessage(data.error);
                setListaUffici([])
                setPassword('')
                setUfficioId('')
                setCambioUfficioModal(false)

                return;
            }
            //se non c'è stato un errore mi arriva un data.roles e arrivo qui
            if (data.roles[0] === "ROLE_MODERATOR") {
                setRuolo("moderator");
                window.localStorage.setItem("ruolo", "moderator"); //vado a mettere in LS per un eventuale refresh della pagina, che sennò perdo la sessione
            } else if (data.roles[0] === "ROLE_USER") {
                setRuolo("user");
                window.localStorage.setItem("ruolo", "user");
            }else if (data.roles[0] === "ROLE_ADMIN") {
                setRuolo("admin");
                window.localStorage.setItem("ruolo", "admin");
            }else if (data.roles[0] === "ROLE_SUPER") {
                setRuolo("super");
                window.localStorage.setItem("ruolo", "super");
            }
            setUserId(data.id);
            window.localStorage.setItem("userId", JSON.stringify(data.id));
    
            setAccessToken(data.accessToken);
            window.localStorage.setItem(
                "accessToken",
                JSON.stringify(data.accessToken)
            );
    
            setUsername(data.username);
            window.localStorage.setItem(
                "username",
                JSON.stringify(data.username)
            );
    
            window.localStorage.setItem(
                "usernameUtenteUfficio",
                JSON.stringify(data.usernameUtenteUfficio)
            );
            window.localStorage.setItem(
                "idUtenteUfficio",
                JSON.stringify(data.idUtenteUfficio)
            );
    
            window.localStorage.setItem("firstAccess", true);

            setCambioUfficioModal(false)
            setPassword('')
            setUfficioId('')
            history.push("/bacheca");
        })
    }

    function sospendiAccount(){
        //se utenteUfficio
        if(window.localStorage.usernameUtenteUfficio){
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
    
            let urlencoded = new URLSearchParams();
            urlencoded.append("utenteUfficioId", window.localStorage.idUtenteUfficio);
            urlencoded.append("userId", window.localStorage.userId);
            urlencoded.append("deleted", false);
    
            let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
    
            fetch(url+"api/v1/utentiUfficio/auth ", requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                if(result.message){
                    localStorage.clear();
                    history.push('/')
                }
                if(result.error){
                    //displayError
                    setErrorMessage(result.error)
                }
            })
            .catch(error => console.log('error', error));
        }else{
            //se user normale
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-user-id", userId);
    
            let urlencoded = new URLSearchParams();
            urlencoded.append("userid", userId);
            urlencoded.append("deleted", false);
    
            let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
    
            fetch(url+"api/v1/users ", requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
                if(result.message){
                    //torni al login 
                    //console.log(result.message)
                    localStorage.clear();
                    history.push('/')
                }
                if(result.error){
                    //displayError
                    //console.log(result.error)
                }
            })
            .catch(error => console.log('error', error));
        }

    }

    return (
        <>
        <div className="header2">
            <div id="menuBars" onClick={() => setOpenSidemenu(!openSidemenu)}>
            <FontAwesomeIcon
                icon={faBars}
                style={{ width: "6vw", heigth: "6vh", marginLeft: "2vw" }}
            ></FontAwesomeIcon>
            </div>

            <img id="logoPrincipale2" src={logo} width="20%" alt="logo"/>
            {props.headImgs && (
            <>
                <div onClick={() => onBellClick()} id="bellContainer">
                <FontAwesomeIcon
                    icon={faBell}
                    id="iconBell"
                    style={{ cursor: cursorBell }}
                />
                <FontAwesomeIcon
                    icon={faCircle}
                    id="iconBellCircle"
                    style={{ color: customizedColor }}
                />
                </div>

                {/* CERCHIO UTENTE */}
                {userController && (
                    <div className="imgContUser" onClick={() => setUserMenu(!userMenu)} > U </div> )}
                {moderatorController && (
                    <div className="imgContMod" onClick={() => setUserMenu(!userMenu)}>M </div> )}
                {adminController && (
                    <div className="imgContMod" onClick={() => setUserMenu(!userMenu)}>A </div> )}
                {superController && (
                    <div className="imgContMod" onClick={() => setUserMenu(!userMenu)}>S </div> )}

                <Modal isOpen={userMenu} onRequestClose={() => setUserMenu(false)} style={modalMenuStyle} id="modalMenuHeader" ariaHideApp={false}>
                    <h4>Username:</h4>
                    {usernameUtenteUfficio!==''&&<div>
                        <p>{usernameUtenteUfficio}</p>
                        <h4>Ufficio:</h4>
                        <p>{username}</p> 
                    </div>}
                    {usernameUtenteUfficio===''&&<div>
                        <p>{username}</p> 
                    </div>}
                    <h4>Ruolo: </h4> 
                    <p> {ruoloCapitalized} </p>
                   {/*  {window.localStorage.usernameUtenteUfficio&&<div className='eliminaAccountButton' onClick={()=>{setInserisciPasswordModal(true);}}>Cambia Ufficio </div>} */}
                    {!superController&&<div className='eliminaAccountButton' onClick={()=>setModale1(true)}>Sospendi Account </div> }
                    {!superController&&<div className='eliminaAccountButton' onClick={()=>setConfermaEliminazioneModal(true)}>Elimina Account </div> }
                    <Link to="/"  className='esciButton' >
                        <h3 style={{display: "inline",color: "white",textDecoration: "none", marginTop:'2vh'}} onClick={cleanAfterLogout}> Esci
                        <FontAwesomeIcon style={{ marginLeft: "8%" }} icon={faDoorOpen}/> </h3>
                    </Link>
                </Modal>

                <Modal isOpen={openPopUp} onRequestClose={() => handleClosePopUp()} style={customStylePopup} id="modaleNotificheHeader" ariaHideApp={false}>
                <div>
                    {messaggiDaLeggere.map((msg) => {
                    return (
                        <div className="anteprimaMessaggio" key={msg.id}>
                            <h4 style={{ display: "inline" }}>{msg.username} </h4>
                            {msg.text!==''?<span>ha scritto: <br />"{msg.text} "</span>:<span>ha inviato un'immagine</span>}
                        </div>
                    );
                    })}
                </div>
                </Modal>
                {confermaEliminazioneModal&&<div className='confermaEliminazioneBg' onClick={(e)=>{if(e.target === e.currentTarget){ e.stopPropagation(); setConfermaEliminazioneModal(false)}}}>
                    <div className='confermaEliminazioneDiv'>
                        <p>Sei davvero sicurə di voler eliminare PER SEMPRE il tuo account?</p>
                        <div style={{display:'flex', justifyContent:'center', margin:'2%'}}>
                            <div style={{margin:'4%', padding:'1vh 2vw'}} className='blueButtons' onClick={()=>{setConfermaEliminazioneModal(false); setInserisciPasswordToDestroyModal(true)}}>SI, ELIMINA</div>
                            <div style={{ margin:'4%', padding:'1vh 2vw'}} className='blueButtons' onClick={()=>setConfermaEliminazioneModal(false)}>NO, ANNULLA</div>
                        </div>
                    </div>
                </div>}

                {inserisciPasswordToDestroyModal&&<div className='confermaEliminazioneBg' onClick={(e)=>{if(e.target === e.currentTarget){ e.stopPropagation(); setInserisciPasswordToDestroyModal(false); setPassword('');setErrorMessage('');setUfficioId('')}}}>
                    <div className='confermaEliminazioneDiv'>
                        <p>Inserisci nuovamente la tua password:</p>
                            <input
                            style={{marginTop:'1em'}}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyPress={handleKeypress2}
                            type={passwordShown?'text':'password'}
                            name="password"
                            className="inputLogin"/>
                            
                            {!passwordShown && (
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="showPasswordIcon"
                                    onClick={()=>setPasswordShown(!passwordShown)}
                                    style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                                />
                            )}
                            {passwordShown && (
                                <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="showPasswordIcon"
                                    onClick={()=>setPasswordShown(!passwordShown)}
                                    style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                                />
                            )}

                            <div style={{display:'flex', justifyContent:'center', marginTop:'3%'}}>
                                <button className="blueButtons" style={{padding:'1vh 2vw'}} onClick={()=>eliminaAccount(null)}>OK</button> 
                            </div> 
                            <SsoButton nextFunction={eliminaAccount}></SsoButton>
                        <p style={{color:'red'}}>{errorMessage}</p>
                    </div>
                </div>}

                {inserisciPasswordModal&&<div className='confermaEliminazioneBg' onClick={(e)=>{if(e.target === e.currentTarget){ e.stopPropagation(); setInserisciPasswordModal(false); setPassword('');setErrorMessage('');setUfficioId('')}}}>
                    <div className='confermaEliminazioneDiv'>
                        <p>Inserisci nuovamente la tua password:</p>
                            <input
                            style={{marginTop:'1em'}}
                            value={password}
                            onChange={(event) => setPassword(event.target.value)}
                            onKeyPress={handleKeypress}
                            type={passwordShown?'text':'password'}

                            name="password"
                            className="inputLogin"/>
                            {!passwordShown && (
                                <FontAwesomeIcon
                                    icon={faEye}
                                    className="showPasswordIcon"
                                    onClick={()=>setPasswordShown(!passwordShown)}
                                    style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                                />
                            )}
                            {passwordShown && (
                                <FontAwesomeIcon
                                    icon={faEyeSlash}
                                    className="showPasswordIcon"
                                    onClick={()=>setPasswordShown(!passwordShown)}
                                    style={{ color: "#0077bc", position: "absolute", marginTop: '1em' }}
                                />
                            )}
                            <div style={{display:'flex', justifyContent:'center', margin:'2%'}}>
                                <div className="blueButtons" style={{margin:'2%', padding:'1vh 3vw'}} onClick={()=>callForLogin()}>OK</div> 
                            </div> 
                            <SsoButton nextFunction={callForLogin}></SsoButton>
                        <p style={{color:'red'}}>{errorMessage}</p>
                    </div>
                </div>}
                {cambioUfficioModal&& <div  className='confermaEliminazioneBg' onClick={(e)=>{if(e.target === e.currentTarget){ e.stopPropagation(); setCambioUfficioModal(false); setPassword(''); setErrorMessage('');setUfficioId('')}}}>
                    <div className='confermaEliminazioneDiv'>
                            <p>Cambia l'ufficio con il quale vuoi entrare:</p>
                            <select onChange={(e)=> setUfficioId(e.target.value)}  style={{marginTop:'3vh'}}>
                                <option value=''>Seleziona un ufficio</option>
                                {listaUffici.map(ufficio=>
                                    {return <option  key={ufficio.id} value={ufficio.id}>{ ufficio.username} </option>}
                                    
                                )}
                                
                            </select>
                            <div style={{display:'flex', justifyContent:'center', margin:'2%'}}>
                                <div className="blueButtons" style={{margin:'2%', padding:'1vh 3vw'}} onClick={()=>callForLogin2()}>OK</div> <br/>
                                <div className="blueButtons" style={{margin:'2%', padding:'1vh 3vw'}} onClick={()=>{setCambioUfficioModal(false); setPassword(''); setErrorMessage('')}}>ANNULLA</div>
                            </div>
                            <p style={{color:'red'}}>{errorMessage}</p>
                        </div>
                    </div>
                }

                {modale1&&<ModaleDiConferme testo={`Sei davvero sicurə di voler sospendere il tuo account? Se lo farai, non potranno più essere disponibili agli altri utenti, i messaggi che vi siete scambiati. L'account verrà automaticamente ripristinato al momento in cui farai di nuovo login.`} funzione={sospendiAccount} setModale={setModale1}></ModaleDiConferme>}

            </>
            )}
        </div>
        </>
    );
}

export default Header;


