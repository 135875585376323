
import React from "react";
import { useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";
import "../style/MessaggiSuperadmin.css";

import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import Header from "../Components/Header.js";
import ArrowsPagination from "../Components/ArrowsPagination.js";
import baseUrl from '../utilities/globalVariables.js'
import SingleMessageSuperadmin from "../Components/SingleMessageSuperadmin.js";




function MessaggiSuperadminPage() {
    const userObject = useContext(UserContext);
    const {userId, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;

    const [listaUffici, setListaUffici] = useState([])
    const [ufficioId, setUfficioId] = useState(2)
    const [listaMsg, setListaMsg] = useState([]);
    //gestisce l'avviso nessun msg ricevuto
    const [listaVuota, setListaVuota] = useState(true); 
    const [errorMessage, setErrorMessage] = useState('')
    
    //stato per loader
    const [loading , setLoading] = useState(true)

    /**stati x pagination*/
    const [ufficioUsername, setUfficioUsername] = useState('Ufficio Segnalazioni')
    const [currentPage, setCurrentPage] = useState(1)
    const [offsetCounter, setOffsetCounter] = useState(1)
    const dataLimit = 6;

    var url=baseUrl
    useEffect(()=>{
        //renderizza la SideBar
        setSidebarRender(true);
    })

    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }

    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }
    
    /** prendo la lista di tutti gli users con ruolo moderator ==== Uffici*/
    useEffect(() =>{
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(url +"api/v1/users/moderators", requestOptions)
        .then(response => {
            //console.log('1111', response);

            if(response.status === 200){
                return response.json();
            }else{
                response.json().then((result)=>{
                    //console.log(result.message);
                })
                return;
            } 
        })
        .then(result => {
            //console.log(result.users)   
            setListaUffici(result.users);
        }) 
        .catch(error => console.log('error', error));
    },[accessToken])

    useEffect(() => { /** una volta che ufficioId è stato scelto, ed è!= 0 vengono presi tutti i suoi inbox*/
        setLoading(true)

        if(ufficioId === 0){
            setListaVuota(false)
            setListaMsg([]);
            setLoading(false)
            setErrorMessage('Nessun ufficio selezionato')
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("x-access-token",  accessToken); 
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-to", ufficioId);
        myHeaders.append("x-ruolo", 'moderator');
        myHeaders.append("x-offsetinput", 0); 
        myHeaders.append("x-dataLimit", 10000); /**non mi interessa dare il limite. gestisco la lista intera FE con la pagination */
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
        fetch(url +"api/v1/messages/inbox/all", requestOptions)
        .then(response =>{
            if(response.status === 200){
                return response.json();
            }else{
                return;
            }
        })
        .then(result => {
            setLoading(false)
            if(result.message.length!==0){
                setListaVuota(false); 
                setListaMsg(result.message);
                //console.log(result.message)
                setErrorMessage('')
            }else if (result.message.length === 0){
                setListaVuota(true); 
                setListaMsg([]);
            }
        })
        .catch(error => console.log('error', error));
    
    },[ufficioId])

    const getPaginatedData = () => { /** function calculates start and end indexes. */
        const startIndex = currentPage * dataLimit - dataLimit;
        const endIndex = startIndex + dataLimit;
        return listaMsg.slice(startIndex, endIndex);
    };

    return(<div className="Appp2">
        <div className="containerRight">
            <Header headImgs="true"></Header>
                <div className="containerMessaggiSuperadminPage">
                    <p>In questa pagina potrai leggere tutti i messaggi ricevuti dai vari uffici.</p>
                    {/* <p style={{margin:'2vh 0'}}> Seleziona un ufficio.</p> */}
                    <label htmlFor="selectUfficio" className="" style={{margin:'2vh 1vw'}}>Seleziona un ufficio
                    <select id="selectUfficio" style={{margin:'0 1vw'}} onChange={(e)=> {setUfficioId(e.target.value) ; setUfficioUsername(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text)}}>
                       {/*  <option value={0}>--</option> */}
                        {listaUffici.map(ufficio=>
                            {return <option  key={ufficio.id} value={ufficio.id}>{ufficio.username} </option>}
                            
                        )}
                        
                    </select>
                    </label>
                   {/*  <p style={{margin:'2vh 0'}}> Qui apparirà la lista dei messaggi ricevuti dall'ufficio selezionato</p> */}
                    <p> {errorMessage}</p>
                    {listaVuota && <p> Nessun messaggio ricevuto!</p>}
                    {loading &&<div className="lds-dual-ring"></div>}
                    
                    {listaMsg.length!==0&&<div>
                        {getPaginatedData().map(msg => (
                            <SingleMessageSuperadmin
                                            key={msg.id} id={msg.id} subject={msg.subject} text={msg.text} flag={msg.flag} to={msg.to} from={msg.from} usernameFrom={msg.username} usernameTo={ufficioUsername} date={msg.createdAt} consensus={msg.consensus} type={msg.type} epoch={msg.epoch}>
                            </SingleMessageSuperadmin>))
                            } 
                    </div>}
                    <div>
                        <ArrowsPagination dataLimit={dataLimit} currentPage = {currentPage} setCurrentPage={setCurrentPage} totalCount={listaMsg.length} setOffsetCounter={setOffsetCounter}></ArrowsPagination>
                    </div>
                </div>
        </div>
    </div>
    )

}

export default MessaggiSuperadminPage;

