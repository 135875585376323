import Modal from "react-modal";
import { useState, useContext,useRef } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../Context/UserContext.js";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { PdfDocument } from "../Components/PdfDocument";
import Recaptcha from "react-google-recaptcha";

import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Delayed from "../Components/Delayed";
import logoDef from "../imgs/logodef.png";
import baseUrl from '../utilities/globalVariables.js'
import siteKey from '../utilities/recaptchaKeys.js'


import "../style/Heads.css";

function RegisterForm(props) {
    //importo context user
    const userObject = useContext(UserContext);
    const {setRuolo, username, setAccessToken, setUserId} = userObject


    const [setLoginPage, setBodyLoginClassName, setBodyLoginClassName2] = [
        props.setLoginPage,
        props.setBodyLoginClassName,
        props.setBodyLoginClassName2
    ];

    const url = baseUrl;


    //dichiaro gli stati che mi servono solo qua
    const [password, setPassword] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [openInfoModal, setOpenInfoModal] = useState(true); //modal in apertura
    const [openModal, setOpenModal] = useState(false); //modal post registrazione

    //regex per il controllo della lunghezza, caratteri maiuscoli o minuscoli e la presenza di un carattere speciale
    const regPwd = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;

    const reRef= useRef() //usato per il token captcha

    //style per gli OVERLAY dei modals
    const customStyleInfoModal = {
        overlay: {
            backgroundColor: "rgb(255 255 255 / 0%)",
            backdropFilter: "blur(4px)",
            zIndex: "2",
        },
    };

    //per delayModale su schermi NON mobile
    let delayModalIfNotMobile;
    if (window.innerWidth > 600) {
        delayModalIfNotMobile = 770;
    } else if (window.innerWidth < 600) {
        delayModalIfNotMobile = 0;
    }

    const handleLoginRegisterPage = () => {
        setBodyLoginClassName("left");
        setBodyLoginClassName2("right2")
        //DELAY DI 0.5S NEL CAMBIAMENTO DI QEUSTO STATO
        if (window.innerWidth > 600) {
            setTimeout(function () {
                setLoginPage(true);
            }, 340);
        } else {
            setLoginPage(true);
        }
    };

    const handleKeypress = (e) => {
        if (e.key === "Enter") {
            sendPostReq();
        }
    };

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(confirmPasswordShown ? false : true);
    };

//se passa tutti i controlli fai il CAPTCHA poi chiama la fetch
    function checksBeforeSendingPost(){ 
         /* controllo FE per le passwords */
        if (password === "" || confirmPassword === "") {
            setErrorMessage("Inserire entrambe le Passwords");
            setDisplayErrorMessage(true);
            return;
        }
        if (password !== confirmPassword) {
            setErrorMessage("Le due Passwords devono corrispondere!");
            setDisplayErrorMessage(true);
            return;
        }
        if (!password.match(regPwd)) {
            setErrorMessage("Le due Passwords non soddisfano i requisiti!");
            setDisplayErrorMessage(true);
            return;
        }
        //console.log('fin qua arriva')

        //DEVE SUPERARE ANCHE IL CONTROLLO DEL CAPTCHA
        reRef.current.executeAsync()
        .then((token)=>{
            //console.log(token);
            reRef.current.reset()
            if(token !== "undefined" || token !== undefined) {sendPostReq(token)}
        })
    }

    function sendPostReq(token) {
        if(token === "undefined" || token === undefined){
            console.log("Captcha failed")
            return
        }
        
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
        urlencoded.append("username", username);
        urlencoded.append("password", password);

        let requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
        };

        fetch(url+"api/v1/auth/signup", requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                //console.log("data:", data);
                if (data.message) {
                    //se c'è stato un errore lei è non vuota.
                    setErrorMessage(data.message);
                    setDisplayErrorMessage(true);
                    return;
                }
                setUserId(data.id);
                setAccessToken(data.accessToken);
                setRuolo("user");
                setOpenModal(true);
                window.localStorage.setItem("userId", JSON.stringify(data.id));
                window.localStorage.setItem("ruolo", "user");
                window.localStorage.setItem(
                    "accessToken",
                    JSON.stringify(data.accessToken)
                );
                window.localStorage.setItem(
                    "username",
                    JSON.stringify(username)
                );
                window.localStorage.setItem("firstAccess", true); //serve in header
            })
        .catch(error => console.log('error', error));

    }

    return (
        <div id="bodyRegisterLeft">
            <Recaptcha  sitekey={siteKey} size="invisible" ref={reRef} />
            {/* MODAL INIZIALE */}
            <Delayed waitBeforeShow={delayModalIfNotMobile}>
                <Modal  isOpen={openInfoModal}  style={customStyleInfoModal}   ariaHideApp={false}  className="infoModalRegistration first"  onRequestClose={() => setOpenInfoModal(false)}  >
                    <h2 className="infoModalTitle">CIAO, SONO HERMÈS!</h2>
                    Per registrarti ti sarà assegnato uno <b>username</b>,<br /> 
                    generato automaticamente e casualmente <br />
                    per garantirti la completa anonimità e ti sarà richiesto di
                    inserire una <b>password</b> che soddisfi alcuni requisiti.
                    <br />
                    <br /> Ricordati di salvare le credenziali <br/>
                    (o scaricare il PDF generato a seguito di avvenuta
                    registrazione), poichè queste non saranno recuperabili in
                    caso di smarrimento. <br />
                    <button  id="infoModalOk"  className="blueButtons"  onClick={() => setOpenInfoModal(false)}  >
                        OK!
                    </button>
                </Modal>
            </Delayed>

            <h2 style={{ textAlign: "center", width: "100%" }} className="titoloRegister">
                REGISTRATI IN HERMÈS 
            </h2>

            <div className="imgMobile">
                    <img src={logoDef} alt="Logo Hermés" />
            </div>

            <label htmlFor="usernameLabel1" style={{ display: "inline" }}>
                Questo sarà il tuo Username 
            </label>
            <br />
            <h3 id="usernameLabel1" style={{ marginBottom: "1%", marginTop: "1em" }}>{username}</h3>

            {displayErrorMessage && (
                <p className="errorMessage">{errorMessage}</p>
            )}

            <label htmlFor="pwdLabel" style={{ marginBottom: "1%" }}>
                Inserisci Password**: 
            </label>

            <input id="pwdLabel" value={password}  onChange={(e) => setPassword(e.target.value)}  type={passwordShown ? "text" : "password"}  name="password" className="inputLogin"  />
            
            {!passwordShown && (
                <FontAwesomeIcon icon={faEye} className="showPasswordIcon" onClick={togglePasswordVisiblity} />
            )}
            {passwordShown && (
                <FontAwesomeIcon icon={faEyeSlash} className="showPasswordIcon" onClick={togglePasswordVisiblity} />
            )}  <br />  <br />

            <label htmlFor="confirmPwdLabel" style={{ marginBottom: "1%" }}> Ripeti Password**: </label>
            
            <input id="confirmPwdLabel"  value={confirmPassword}  onChange={(e) => setConfirmPassword(e.target.value)}  type={confirmPasswordShown ? "text" : "password"} name="confirmPassword" className="inputLogin" onKeyPress={handleKeypress} />
            {!confirmPasswordShown && (
                <FontAwesomeIcon  icon={faEye}  className="showPasswordIcon" onClick={toggleConfirmPasswordVisiblity}/>
            )}
            {confirmPasswordShown && (
                <FontAwesomeIcon  icon={faEyeSlash}  className="showPasswordIcon"  onClick={toggleConfirmPasswordVisiblity}  />)} <br />  <br />
            <button  onClick={checksBeforeSendingPost}  className="buttonSignin " id="registerButton">REGISTRAMI
            </button>  <br /> 

            <p className="clickHere" onClick={() => handleLoginRegisterPage()}>
                Sei già registratə? Clicca qui!
            </p>

            <p className="regexpDescrP">
                **La Password deve contenere almeno 8 caratteri di cui: almeno
                una minuscola, una maiuscola, un numero ed un carattere speciale
                (!@#$%^&*). 
            </p>
            
            {/* MODAL POST REGISTRAZIONE */}
            <Modal isOpen={openModal}  style={customStyleInfoModal}  className="infoModalRegistration"  ariaHideApp={false} >
                <h2 className="infoModalTitle" style={{fontSize:'xLarge'}}> BENVENUTO SU HERMÈS </h2>Stai
                per utilizzare uno strumento potentissimo che ti permetterà di
                dar voce in forma completamente anonima ad ogni tuo dubbio,
                problema, suggerimento o richiesta di chiarimento. <br /> <br />E’ presente
                una bacheca generale dove sono riportati tutti gli altri
                messaggi di persone come te, ti chiediamo quindi di non aprire
                subito una nuova richiesta, ma di controllare prima se un
                argomento simile sia già stato affrontato, ed eventualmente
                votarlo! <br />(Apri l'avviso che ti interessa e poi lascia un voto
                secondo le tue opinioni!)
                <br /> <br />
                Per mantenere questo spazio sempre piacevole, ti preghiamo di
                rispettare te stesso e gli altri utilizzando toni gentili e
                civili. <br /> <br /> Ricordati che il tuo username è 
                <b style={{ color: "#0077bc", margin:'0 1vh' }}>{username}</b> <br /> <br />
                <Link to="/bacheca">
                    <button className="blueButtons registerButtons">
                        OK, HO CAPITO!
                    </button>
                </Link>
                <PDFDownloadLink  document={ <PdfDocument username={username} password={password} /> }  fileName="Hermès.pdf"  className="whiteButtons registerButtons" style={{ textDecoration: "none" }}  >
                    SCARICA PDF
                </PDFDownloadLink>
            </Modal>
            <div className="privacyLegalHomePage"  > 
                <a href="https://www.avrgroup.it/azienda/modello-231/privacy-policy" target="_blank" rel="noreferrer" style={{left:'1vw'}}>Privacy  &amp;  Legal</a>
            </div>
        </div>
    );
}

export default RegisterForm;

/* Magia della password: 
type={passwordShown ? "text" : "password"} 
*/
