
import React, {useState, createContext} from 'react';

export const ClickedMessageContext = createContext();

export const ClickedMessageProvider = props =>{

    const [clickedMessage, setClickedMessage] = useState({}); 

    var clickedMessageCont = {
        clickedMessage: clickedMessage,
        setClickedMessage: setClickedMessage
    };

    return(
        <ClickedMessageContext.Provider value ={ clickedMessageCont  }>
            {props.children}
        </ClickedMessageContext.Provider>
    );
}