
import React, {useState, useContext, useEffect}  from 'react';
import NoticesContainer  from '../Containers/NoticesContainer.js';
import Header from '../Components/Header.js';
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faClock} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../style/Heads.css"


function BachecaPage() {

  const sideBarContext = useContext(SidebarRenderContext);
  const setSidebarRender = sideBarContext.setSidebarRender;

  const [orderedByDate, setOrderedByDate] = useState(true);

  //renderizza la SideBar
  useEffect(()=>{
    setSidebarRender(true);
  },[])

    return (
      <div className="Appp2"> 
        <div className="containerRight">
          <Header headImgs='true'></Header>
          <div className="containersVari">
            < div className="divContainer">
            <div className="columnsHead">
                <div className="headBacheca">
                    <h2 className="bachecaAvvisi"> BACHECA {/* <FontAwesomeIcon icon={faInfoCircle} id= "faInfoBacheca"></FontAwesomeIcon> */}</h2>
                    {/* <div className="" > 
                        Nella bacheca vengono raccolti tutti gli avvisi creati dai moderatori e tutte le segnalazioni, 
                        reclami, suggerimenti inviate dagli altri utenti. Ogni avviso può essere votato una volta aperto.</div> */}
                    <h4 id="ordinaPer">Ordina avvisi per:</h4>
                    <div id="orderButtonsContainer">
                        <button id = "dataButton" className={orderedByDate?'blueButtons':'whiteButtons'} type="button" onClick={() =>setOrderedByDate(true)}><FontAwesomeIcon icon={faClock} style={{marginRight:'5%'}}/>DATA </button>
                        <button id = "rateButton" className={orderedByDate?'whiteButtons':'blueButtons'} type="button" onClick={() =>setOrderedByDate(false)}><FontAwesomeIcon icon={faThumbsUp}/> POPOLARITA'</button>
                    </div>
                </div>
            </div>
            <div >
                <NoticesContainer
                    orderedByDate={orderedByDate}>
                </NoticesContainer>
            </div>
        </div>
          </div>
        </div>
      </div>
    );
  }

  export default BachecaPage;