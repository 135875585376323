
import React from 'react';
import {useState, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {UserContext} from "../Context/UserContext.js";
import "../style/GestioneUtenti.css";

import Header from '../Components/Header.js';
import ModaleDiConferme from '../Components/ModaleDiConferme.js';
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";
import baseUrl from '../utilities/globalVariables.js'

function GestioneUtentiPage() {
    const userObject = useContext(UserContext);
    const {userId, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject
    
    var url=baseUrl
    
    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    const [listaUsers, setListaUsers] = useState([]);
    const [renderAutomatico, setRenderAutomatico] = useState(false)
    const [loading , setLoading] = useState(false)
    const [listaUtentiAssociati, setListaUtentiAssociati] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    
    const [modale1, setModale1] = useState(false)
    const [modale2, setModale2] = useState(false)
    const [utenteUfficioIdSelected, setUtenteUfficioIdSelected] = useState(null)
    const [ufficioIdSelected, setUfficioIdSelected] = useState(null)
    
    //modifica ufficio nomi
    const [updating, setUpdating] = useState(0)
    const [ufficioNome, setUfficioNome] = useState('')
    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])


    //console.log(window.localStorage)
    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }
    
    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }

    function handleDelete(e){
        let deleted;
        if(e.target.parentNode.getAttribute('deleted') === "si"){
            deleted = true;
        }else if(e.target.parentNode.getAttribute('deleted') === "no"){
            deleted = false;
        }
        //console.log(deleted);

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var urlencoded = new URLSearchParams();
        urlencoded.append("userid", e.target.parentNode.id);
        urlencoded.append("deleted", deleted);

        var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(url+"api/v1/users ", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log(result)
            setRenderAutomatico(!renderAutomatico)
        })
        .catch(error => console.log('error', error));

    }
    
    //onload chiamo la lista di tutti gli uetnti users e mod
    useEffect(()=>{
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url+"api/v1/users", requestOptions)
        .then(response => response.json())
        .then(result => {
            //console.log("lista utenti", result.users)
            if(result.users!==undefined){setListaUsers(result.users)}
            //console.log(listaUsers)
            setLoading(false)
        })
        .catch(error => console.log('error', error));
    },[accessToken, renderAutomatico])
    
    /**gestito dal modale1 */
    function removeRelationshipWithUsers(){
        if(!ufficioIdSelected||!utenteUfficioIdSelected){
            return
        }

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);

        var urlencoded = new URLSearchParams();
        urlencoded.append("utenteUfficioId", utenteUfficioIdSelected);
        urlencoded.append("ufficioId", ufficioIdSelected);


        var requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
        };

        fetch(url +"api/v1/utentiUfficio", requestOptions)
        .then(response => response.json())
        .then(result=>{
            //console.log(result)
            if(result.message){
                //torni al login 
                //console.log(result.message)
                setErrorMessage(result.message)
                setRenderAutomatico(!renderAutomatico)

                setUtenteUfficioIdSelected()
                setUfficioIdSelected()
                setModale1(false)

            }
            if(result.error){
                //displayError
                setErrorMessage(result.error)
                setRenderAutomatico(!renderAutomatico)

                setUtenteUfficioIdSelected()
                setUfficioIdSelected()
                setModale1(false)


                console.log(result.error)
            }
        })
        .catch(error => console.log('error', error));
    }

    /**gestito dal modale2 */
    function destroyUtenteUfficio(){
        if(!ufficioIdSelected){
            return
        }
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-utenteufficio-id", ufficioIdSelected);

        var requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch( url+"api/v1/utentiUfficio/auth", requestOptions )
        .then(response=> response.json())
        .then(result=>{
            //console.log(result)
            if(result.message){
                //torni al login 
                //console.log(result.message)
                setErrorMessage(result.message)
                setModale2(false)
                setRenderAutomatico(!renderAutomatico)
                setUfficioIdSelected()

            }
            if(result.error){
                //displayError
                setErrorMessage(result.error)
                //console.log(result.error)
                setRenderAutomatico(!renderAutomatico)
                setModale2(false)
                setUfficioIdSelected()
            }
        })
    }
    function updateUfficioName(){
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-utenteufficio-id", updating);
        myHeaders.append("x-nomeufficio", ufficioNome);

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
        };

        fetch( url+"api/v1/utentiUfficio/auth", requestOptions )
        .then(response=> response.json())
        .then(result=>{
            //console.log(result)
            if(result.message){
                //torni al login 
                //console.log(result.message)
                setErrorMessage(result.message)
                setUpdating(0); 
                setUfficioNome('')
                setUpdating()
                setRenderAutomatico(!renderAutomatico)

            }
            if(result.error){
                //displayError
                setErrorMessage(result.error)
                setUpdating(0); 
                setUfficioNome('')
            }
        })
      

    }

    return (
        <div className="Appp2"> 
            <div className="containerRight">
                <Header headImgs='true'></Header>
                {modale1&&<ModaleDiConferme testo={'Sei davvero sicurə di volerli disassociare?'} funzione={removeRelationshipWithUsers} setModale={setModale1}></ModaleDiConferme>}
                {modale2&&<ModaleDiConferme testo={'Sei davvero sicurə di voler eliminare per sempre questo utente ufficio?'} funzione={destroyUtenteUfficio} setModale={setModale2}></ModaleDiConferme>}
                <div className="containerGestioneUtenti"  >
                    <div className="headGestioneUtenti">In questa pagina è possibile disattivare, ripristinare o eliminare definitivamente un account.
                    Si può anche disassociare un utente da un ufficio</div>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        {!listaUtentiAssociati&&<div className="mostraUtentiButton" onClick={()=>setListaUtentiAssociati(!listaUtentiAssociati)} >Vedi utenti associati agli uffici</div>}
                        {listaUtentiAssociati&&<div className="mostraUtentiButton" onClick={()=>setListaUtentiAssociati(!listaUtentiAssociati)} >Nascondi utenti associati agli uffici</div>}
                    </div>
                    <p>{errorMessage}</p>

                    <div className="innerDivContainer">
                    {loading &&<div className="lds-dual-ring"></div>}
                    {listaUsers.map((user,j) => {
                            let [userButton, moderatorButton,deleted]=[false, false,'si'];
                            if(user.roles[0].name==="Moderator"){
                                moderatorButton=true;
                            }else if (user.roles[0].name==="User"){
                                userButton=true;
                            }else if (user.roles[0].name==="Admin"){
                                return
                            }else if (user.roles[0].name==="Super"){
                                return
                            }
                            if(user.deleted===false){
                                deleted = 'no';
                            }
                        return(
                            <div key={j} >
                                <div className="singleUser" 
                                    id={user.id} ruolo={user.roles[0].name} deleted={deleted}> 
                                    {updating!==user.id&&<span><b>{user.username} </b></span> }
                                    {updating===user.id&&<input className= "inputTopic" style={{width:'16vw'}} value={ufficioNome} onChange={(e) => setUfficioNome(e.target.value)}></input> }
                                    <span>{user.roles[0].name}</span>
                                    
                                    {!user.deleted&& <div className="usersButtons" onClick={(e)=>handleDelete(e)}>Disattiva utente</div>}
                                    {user.deleted&& <div className="usersButtons" style={{color:'red'}} onClick={(e)=>handleDelete(e)}>Ripristina utente</div>}
                                    {(user.roles[0].name === "Moderator" && updating!==user.id)&&<div className="usersButtons"  onClick={()=>{setUpdating(user.id); setUfficioNome(user.username)}}>Modifica</div>}
                                    {(user.roles[0].name === "Moderator" && updating===user.id)&&<div className="usersButtons"  onClick={()=>{updateUfficioName()}}>Salva</div>}

                                </div>
                                { user.utentiufficios.length!=0&&<div className={`utentiUfficioLista ${listaUtentiAssociati?"utentiUfficioListaAperta":""}`}>
                                    { user.utentiufficios.map((utente,i)=>{
                                        return <div key={i+'a'} className="utentiUfficioRiga">
                                            <span>{utente.username}</span>
                                            <span  className="utentiUfficioButtons" 
                                                    onClick={()=>{setUtenteUfficioIdSelected(utente.id); setUfficioIdSelected(user.id); setModale1(true)}}>
                                                        Disassocia utente da questo ufficio
                                            </span>
                                            <span id={utente.id} className="utentiUfficioButtons" 
                                                    onClick={(e)=>{setUfficioIdSelected(e.target.id);  setModale2(true)}}>
                                                        Elimina questo utente definitivamente
                                            </span>
                                    </div>})
}
                                </div>}
                                
                            </div>)})} 
                    </div>
                </div>
            </div>
        </div>
        );
    }

export default GestioneUtentiPage;


/* 






*/