import React from 'react';
import"../style/SingleNotice.css";

import { faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { faClock} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function SingleNotice(props){

    const setOpenPopUp= props.setOpenPopUp; 
    const setClickedNotice= props.setClickedNotice;  
    
    //formatto orario creazione
    var epoch = props.epoch;
    var data = new Date(+epoch).toLocaleString('it-IT');
    var orario = data.split(", ")[1].split(":");
    orario = orario[0] +":"+ orario[1];
    data = data.split(", ")[0];

    function handleClick(){
        setOpenPopUp(true);
        setClickedNotice({
            title:props.title, text: props.text,id: props.id, rating: props.rating, data: data, orario: orario, positivesRates: props.positivesRates, negativesRates: props.negativesRates
        });
    }

    return(
        <div onClick={handleClick} className="singleNotice">
            <div className="topicNotice">{props.title} <div className="textNotice">{props.text}</div></div>
            <div className="ratingNotice"><FontAwesomeIcon icon={faStarHalfAlt} className="iconeCardNotices"></FontAwesomeIcon>
            <span style={{color:'green', fontWeight:'bold', fontSize:'larger',marginRight:'0.5vw'}}>{props.positivesRates} </span> <span style={{marginLeft:'0.5vw',color:'red', fontWeight:'bold', fontSize:'larger'}}>{props.negativesRates}</span></div>
            <div className="dataNotice"><FontAwesomeIcon icon={faClock} className="iconeCardNotices"></FontAwesomeIcon>{orario+" - "+data}</div>
            
        </div>
    );
}
export default SingleNotice;

