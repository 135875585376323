import React, { useState, useEffect, useContext } from "react";
import "../style/StatsPage.css";
import {useHistory} from "react-router-dom";

import Header from "../Components/Header.js";
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
} from "recharts";
import TempisticheComponent from "../Components/TempisticheComponent.js"
import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import baseUrl from '../utilities/globalVariables.js'


function StatsPage() {
    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    
    const userObject = useContext(UserContext);
    const {userId, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const [primaData, setPrimaData] = useState("");
    const [secondaData, setSecondaData] = useState("");
    const [listaUffici, setListaUffici] = useState([])
    const [arrayUffici, setArrayUffici] = useState([]);

    const [segnalazione, setSegnalazione] = useState(false);
    const [reclamo, setReclamo] = useState(false);
    const [suggerimento, setSuggerimento] = useState(false);
    const [altro, setAltro] = useState(false);

    const [dataChart, setDataChart] = useState();
    const [numeriButton, setNumeriButton] = useState(true);
    const [tempiButton, setTempiButton] = useState(false);
    const[avvisoIstruzione, setAvvisoIstruzione] = useState('')

    let dataChart2=[]; //Oggetto appoggio x dati da manipolare per disegnare grafico
    let dateArray =[]; //array in cui metto le date selezionate e che poi manipolo per creare dataChart
    let url=baseUrl


    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();

    if (dd < 10) {
    dd = '0' + dd;
    }

    if (mm < 10) {
    mm = '0' + mm;
    } 
        
    today = yyyy + '-' + mm + '-' + dd;

    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])

    let history = useHistory();
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }
    
    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }

    //CREO array di date between primaData and secondaData --LO RIEMPIO AUTOMATICAMENTE
    // eslint-disable-next-line no-extend-native
    Date.prototype.addDays = function (days) {
        let date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    function getDates(startDate, stopDate) {
        let currentDate = new Date(startDate);
        //console.log("currentDate",currentDate)
        stopDate = new Date(stopDate);
        while (currentDate <= stopDate) {
            let varTemp = new Date(currentDate)
                .toLocaleString("it-IT")
                .split(", ")[0];
            varTemp = [varTemp.split("/")[0], varTemp.split("/")[1]]; //splitto e join per togliere anno
            dateArray.push(varTemp.join("/"));
            currentDate = currentDate.addDays(1);
        }
        return dateArray;
    }

    if (primaData!=="" && secondaData!=="") {
        getDates(primaData, secondaData);
        //console.log("dateArray",dateArray)
        dataChart2 = dateArray.map((item) => {
        let properties = {
            data: item,
            NumeroSegnalazioni: 0,
            NumeroReclami: 0,
            NumeroSuggerimenti: 0,
            NumeroAltro: 0,
        };
        return properties;
        });
    }


        
    //prendo la lista di tutti gli users con ruolo moderator per popolare la lista uffici=> listaUffici
    useEffect(() =>{
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        let requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url+"api/v1/users/moderators ", requestOptions)
        .then(response => {
            if(response.status === 200){
                return response.json();
            }else{
                response.json().then((result)=>{
                })
                return;
            } 
        })
        .then(result => {
            setListaUffici(result.users);
        }) 
        .catch(error => console.log('error', error));
    },[accessToken])

    //chiama tutti i messaggi richiesti, poi li formatta dentro handleResultFromAPI
    useEffect(() => {
        if (arrayUffici.length===0 || arrayUffici===[]) {
            console.log("selezionare almeno un destinatario");
            setAvvisoIstruzione("Selezionare almeno un destinatario")
            setDataChart([]);
            return;
        }
        //controllo che siano stati premuti i pulsanti minimi prima di far partire fetch
        if (!segnalazione && !reclamo && !suggerimento && !altro) {
            console.log("selezionare almeno un tipo");
            setAvvisoIstruzione('Selezionare almeno un tipo')
            setDataChart([]);
            return;
        }
        
        if (!primaData || !secondaData) {
            console.log("selezionare le date per l'intervallo");
            setAvvisoIstruzione("Selezionare le date per l'intervallo")
            setDataChart([]);
            return;
        }
        setAvvisoIstruzione("")
        //array che uso BE per sapere come filtrare per TIPO
        let types = [];
        if (segnalazione) {
        types.push(1);
        }

        if (reclamo) {
        types.push(2);
        } 

        if (suggerimento) {
        types.push(3);
        } 

        if (altro) {
        types.push(4);
        } 

        //console.log(primaData, secondaData) //2024-03-14
        let myHeaders = new Headers();
        myHeaders.append("x-user-id", userId);
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        myHeaders.append("x-types", types);
        myHeaders.append("x-uffici", arrayUffici);
        myHeaders.append("x-primaData", primaData);
        myHeaders.append("x-secondaData", secondaData);

        let requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(url+"api/v1/messages/charts", requestOptions)
        .then((response) => {
            if (response.status === 200) {
                return response.json();
            } else {
                return;
            }
        })
        .then((result) => {
            //resultFromAPI = result.messaggi;
            //console.log("messaggi", result);
            handleResultFromAPI(result.items);
        })
        .catch((error) => console.log("error", error));
    }, [segnalazione, suggerimento, reclamo, altro, arrayUffici, primaData, secondaData]);

    function handleResultFromAPI(resultFromAPI) {
        for (let i = 0; i < resultFromAPI.length; i++) {
        let dataMessage = new Date(+resultFromAPI[i].epoch).toLocaleString(
            "it-IT"
        );
        dataMessage = dataMessage.split(", ")[0];
        dataMessage = [dataMessage.split("/")[0], dataMessage.split("/")[1]]; //splitto e join per togliere anno
        dataMessage = dataMessage.join("/");
        let typeMessage = resultFromAPI[i].type;

        if (typeMessage === 1) {
            //segnalazione
            for (let j = 0; j < dataChart2.length; j++) {
            if (dataChart2[j].data === dataMessage) {
                let somma = dataChart2[j].NumeroSegnalazioni + 1;
                Object.assign(dataChart2[j], { NumeroSegnalazioni: somma });
            }
            }
        }
        if (typeMessage === 2) {
            //reclamo
            for (let j = 0; j < dataChart2.length; j++) {
            if (dataChart2[j].data === dataMessage) {
                let somma = dataChart2[j].NumeroReclami + 1;
                Object.assign(dataChart2[j], { NumeroReclami: somma });
            }
            }
        }
        if (typeMessage === 3) {
            //suggerimento
            for (let j = 0; j < dataChart2.length; j++) {
            if (dataChart2[j].data === dataMessage) {
                let somma = dataChart2[j].NumeroSuggerimenti + 1;
                Object.assign(dataChart2[j], { NumeroSuggerimenti: somma });
            }
            }
        }
        if (typeMessage === 4) {
            //altro
            for (let j = 0; j < dataChart2.length; j++) {
            if (dataChart2[j].data === dataMessage) {
                let somma = dataChart2[j].NumeroAltro + 1;
                Object.assign(dataChart2[j], { NumeroAltro: somma });
            }
            }
        }
        }
       // console.log("dataChart2", dataChart2);
        setDataChart(dataChart2);
        return dataChart2;
    }
    /**chiamata al click di ogni bottone, aggiunge o rimuove l'ID dell'ufficio cliccato */
    function handleArrayUffici(e){
        //gestione classi bottoni
        if(e.target.className === "notClicked"){
            e.target.className= "clicked"
        }else if (e.target.className === "clicked"){
            e.target.className= "notClicked"
        }
        let valoreBottone= +e.target.value; 
        if (!arrayUffici.includes(valoreBottone)){ /**se non c'è - aggiungi*/
            setArrayUffici(prev => [...prev, valoreBottone])

        }else{ /**se c'era - togli*/
            arrayUffici.splice(arrayUffici.indexOf(valoreBottone), 1)
            setArrayUffici([...arrayUffici])
        }
    }

    return (
        <div className="statsPage">
        <Header headImgs="true"></Header>
        <div className="statsButtonContainer">
            <button className={`statsButtons ${ numeriButton === true ? 'blueButtons':'whiteButtons'}`} onClick={()=>{setNumeriButton(true);setTempiButton(false)}}>NUMERI</button>
            <button className={`statsButtons ${ tempiButton === true ? 'blueButtons':'whiteButtons'}`} onClick={()=>{setNumeriButton(false);setTempiButton(true)}}>TEMPI</button>
        </div>
            {numeriButton&&<div className="statsPageContainer">
                <div className="headStatsPage">
                Per visualizzare su grafico le statistiche, selezionare almeno <b>un tipo </b> 
                di messaggio, <b>un destinatario</b> e <b>un intervallo</b> di date. Premendo più
                bottoni insieme si potranno graficare statistiche diverse.</div>
                
                <div className="bodyGridStats">
                    <div className="leftButtonsStats"> 
                        {listaUffici.map((ufficio)=>{
                                return <button className = "notClicked" key={ufficio.id} id={ufficio.id} value={ufficio.id} onClick={(e)=>handleArrayUffici(e)}>{ufficio.username} </button>
                            })
                        }
                    </div>
                    <div className="chartContainer">
                        <span className="avvisoChart">{avvisoIstruzione}</span>

                        <ResponsiveContainer width="90%" height="85%">
                        <LineChart data={dataChart}>
                            <XAxis dataKey="data" />
                            <YAxis width={30} />
                            <Tooltip />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            {segnalazione && (
                            <Line
                                type="monotone"
                                dataKey="NumeroSegnalazioni"
                                stroke="red"
                                strokeWidth={2}
                            />
                            )}
                            {reclamo && (
                            <Line
                                type="monotone"
                                dataKey="NumeroReclami"
                                stroke="#0077bc"
                                strokeWidth={2}
                            />
                            )}
                            {suggerimento && (
                            <Line
                                type="monotone"
                                dataKey="NumeroSuggerimenti"
                                stroke="green"
                                strokeWidth={2}
                            />
                            )}
                            {altro && (
                            <Line
                                type="monotone"
                                dataKey="NumeroAltro"
                                stroke="orange"
                                strokeWidth={2}
                            />
                            )}
                        </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="rightButtonsStats">
                        <button
                        type="button"
                        className={segnalazione ? "selectedRed" : ""}
                        onClick={() => setSegnalazione(!segnalazione)}
                        >
                        Segnalazioni
                        </button>
                        <button
                        type="button"
                        className={reclamo ? "selectedBlue" : ""}
                        onClick={() => setReclamo(!reclamo)}
                        >
                        Reclami
                        </button>
                        <button
                        type="button"
                        className={suggerimento ? "selectedGreen" : ""}
                        onClick={() => setSuggerimento(!suggerimento)}
                        >
                        Suggerimenti
                        </button>
                        <button
                        type="button"
                        className={altro ? "selectedOrange" : ""}
                        onClick={() => setAltro(!altro)}
                        >
                        Altro
                        </button>
                    </div>
                </div>
                <div className="bottomButtonsStats">
                <label htmlFor="primaData" style={{display:'inline'}}>   Dal:  </label>
                <input
                    value={primaData}
                    onChange={(event) => setPrimaData(event.target.value)}
                    type="date"
                    id="primaData"
                    className="inputLogin inputData"
                    min="2021-06-01"
                    max={today}
                ></input>
                <label htmlFor="secondaData" style={{display:'inline'}}>   Al:  </label>
                <input
                    value={secondaData}
                    onChange={(event) => setSecondaData(event.target.value)}
                    type="date"
                    id="secondaData"
                    className="inputLogin inputData"
                    min="2021-06-01"
                    max={today}
                    
                ></input>
                </div>
            </div>}

            {tempiButton && <TempisticheComponent />}
            
        </div>
    );
}
export default StatsPage;



/* ATTENZIONE PERCHE IL GRAFICO DEI NUMERI SE INSERISCI UN ARCO TEMPORALE DI PIU ANNI FA UN CALCOLO CICLICO WTF*/ 