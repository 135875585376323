import React from "react";
import { useState, useContext, useEffect} from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../Context/UserContext.js";
import { SidebarRenderContext } from "../Context/SidebarRenderContext.js";
import Header from "../Components/Header.js";

import InboxContainer from "../Containers/InboxContainer.js";
import SentContainer from "../Containers/SentContainer.js";
import StoredContainer from "../Containers/StoredContainer.js";
import "../style/Heads.css"

import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import { faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function MessaggiPage() {
  let history = useHistory();
  if(window.localStorage.length === 0 || window.localStorage.username === undefined){
    history.push('/');
  }

  const userObject = useContext(UserContext);
  const { setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

  const sideBarContext = useContext(SidebarRenderContext);
  const setSidebarRender = sideBarContext.setSidebarRender;
  
  /**stati per gestire il render dei contenitori*/
  const [inbox, setInbox] = useState(true);
  const [sent, setSent] = useState(false);
  const [stored, setStored] = useState(false);
  
    if (accessToken === "") {
        setRuolo(window.localStorage.ruolo);
        setUsername(JSON.parse(window.localStorage.username));
        setAccessToken(JSON.parse(window.localStorage.accessToken));
        setUserId(window.localStorage.userId); 
    }

  /**renderizza la SideBar*/
  useEffect(()=>{
    setSidebarRender(true);
  },[])


  return (
    <div className="Appp2">
      <div className="containerRight">
        <Header headImgs="true"></Header>
        <div className="containersVari">
            <div className="headMessage">
                <h2 className="messagesTitle">MESSAGGI </h2>
                <div id="containerRicevutiInviati">
                    <button id="ricevutiButton"     className={inbox?'blueButtons':'whiteButtons'}  aria-label="Mostra i ricevuti" type="button" onClick={() => {setInbox(true); setSent(false); setStored(false)}} > <FontAwesomeIcon icon={faSignInAlt} style={{marginRight:'1vw'}} />RICEVUTI</button>
                    <button id="inviatiButton"      className={sent?'blueButtons':'whiteButtons'}   aria-label="Mostra gli inviati" type="button" onClick={() => {setInbox(false); setSent(true); setStored(false)}} >INVIATI <FontAwesomeIcon icon={faSignOutAlt} style={{marginLeft:'0.7vw'}} /></button> 
                    <button id="archiviatiButton"   className={stored?'blueButtons':'whiteButtons'} aria-label="Mostra le chat archiviate" type="button" onClick={() => {setInbox(false); setSent(false); setStored(true)}} >CONVERSAZIONI ARCHIVIATE <FontAwesomeIcon icon={faFolderOpen} style={{marginLeft:'0.7vw'}} /></button> 
                </div>
            </div>
            {inbox  && <InboxContainer></InboxContainer>}
            {sent   && <SentContainer></SentContainer>}
            {stored && <StoredContainer></StoredContainer>}
        </div>
      </div>
    </div>
  );
}

export default MessaggiPage;

