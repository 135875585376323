import React, {useState, createContext} from 'react';

export const SidebarRenderContext = createContext();

export const SidebarRenderProvider = props =>{
    const [sidebarRender, setSidebarRender] = useState(false)

    var sideBarContext = {
        sidebarRender:sidebarRender,
        setSidebarRender:setSidebarRender
    }
    return(
        <SidebarRenderContext.Provider value = { sideBarContext  }>
            {props.children}
        </SidebarRenderContext.Provider>
    );
}

/* 
questo context serve per far renderizzare o meno la sidebar:
nella HomePage x login e registration non vogliamo la sidebar!!
 */