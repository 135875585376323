
import React from 'react';
import {useState, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {UserContext} from "../Context/UserContext.js";
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";
import "../style/CreazioneUffici.css";

import Header from '../Components/Header.js';
import baseUrl from '../utilities/globalVariables.js'

function CreaUffici() {
    const userObject = useContext(UserContext);
    const {userId, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const url=baseUrl

    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    const [usernameUfficio, setUsernameUfficio]= useState("");
    const [passwordUfficio, setPasswordUfficio]= useState("");
    const [mailUtente, setMailUtente]= useState('');

    const [errorMessage,setErrorMessage]= useState();
    const [confirmationMessage, setConfirmationMessage] = useState("")
    const [createUfficio, setCreateUfficio] = useState(true)

    const [listaUffici, setListaUffici] =useState([])
    const [ufficioId, setUfficioId] = useState('0')
    const [usernameUtenteUfficio, setUsernameUtenteUfficio]= useState("");
    const [passwordUtenteUfficio, setPasswordUtenteUfficio]= useState("");

    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])
    //prendo la lista di tutti gli users con ruolo moderator === Uffici
    useEffect(() =>{
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(url +"api/v1/users/moderators", requestOptions)
        .then(response => {
            //console.log('1111', response);

            if(response.status === 200){
                return response.json();
            }else{
                response.json().then((result)=>{
                    //console.log(result.message);
                })
                return;
            } 
        })
        .then(result => {
            //console.log(result.users)   
            setListaUffici(result.users);
        }) 
        .catch(error => console.log('error', error));
    },[accessToken, confirmationMessage])

    useEffect(()=>{
        setErrorMessage('')
        setConfirmationMessage('')
        setUfficioId('0')
        setUsernameUtenteUfficio('')
        setPasswordUtenteUfficio('')
        setUsernameUfficio('')
        setPasswordUfficio('')
        setMailUtente('')
    },[createUfficio])

    let history = useHistory()

    if(accessToken === ""){
        if(window.localStorage.accessToken){
            setRuolo(window.localStorage.ruolo); //sono già stringhe
            setUsername(JSON.parse(window.localStorage.username));
            setAccessToken(JSON.parse(window.localStorage.accessToken));
            setUserId(window.localStorage.userId); //sono già stringhe
        } else {
            history.push ("/")
        }
    }
    //regex per il controllo della lunghezza, caratteri maiuscoli o minuscoli e la presenza di un carattere speciale
    const regPwd = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    function creaUfficio(){
        /* controllo FE per passwords */
        if(usernameUfficio === ""){
            setErrorMessage("Inserire un Username");
            setConfirmationMessage('')
            return;
        }
        if (passwordUfficio === "" ) {
            setErrorMessage("Inserire una Password");
            setConfirmationMessage('')
            return;
        }

        //controllo FE la pwd
        /* SE SODDISFANO I REQUISITI O MENO VIENE PER ORA GESTITE BE NON CONTROLLATO FE */
        if (!passwordUfficio.match(regPwd)) {
            setErrorMessage("La Password non soddisfa i requisiti!");
            setConfirmationMessage('')
            return;
        }

        let myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        let urlencoded = new URLSearchParams();
        urlencoded.append("username", usernameUfficio);
        urlencoded.append("password", passwordUfficio);
        urlencoded.append("ruolo", 1);

        let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
        };

        fetch(url+"api/v1/users/moderators", requestOptions)
        .then(response => {
            if(response.status === 200){
                return response.json();
            }else{
                response.json().then((result)=>{
                    //console.log(result.message);
                    setErrorMessage(result.message);
                })
                setConfirmationMessage('')
                return;
            }
        })
        .then(result => {
            //console.log(result.message)
            setUsernameUfficio("");
            setPasswordUfficio("");
            setConfirmationMessage(result.message)
            setErrorMessage('');
        })
        .catch(error => console.log('error', error));
    }
    
    //console.log(ufficioId, typeof ufficioId)

    function creaUtenteUfficio(){
        if ( ufficioId ==='0' || ufficioId === 0) {
            setErrorMessage("Scegli un Ufficio");
            setConfirmationMessage('')
            return;
        }
        if(usernameUtenteUfficio === "" ){
            setErrorMessage("Inserisci un Username");
            setConfirmationMessage('')
            return;
        }
        if (mailUtente === ""&&passwordUtenteUfficio === "" ) {
            setErrorMessage("Inserire una Password o una Mail");
            setConfirmationMessage('')
            return;
        }
        if(usernameUtenteUfficio!==''&&passwordUtenteUfficio!==''){
            //console.log('primo')

            //controllo FE la pwd
            /* SE SODDISFANO I REQUISITI O MENO VIENE PER ORA GESTITE BE NON CONTROLLATO FE */
            if (!passwordUtenteUfficio.match(regPwd)) {
                setErrorMessage("La Password non soddisfa i requisiti!");
                setConfirmationMessage('')
                return;
            }

            let myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-user-id", userId);

            let urlencoded = new URLSearchParams();
            urlencoded.append("username", usernameUtenteUfficio);
            urlencoded.append("password", passwordUtenteUfficio);
            urlencoded.append("ufficioId", ufficioId);

            const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };

            fetch(url+"api/v1/utentiUfficio", requestOptions)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(result);
                if(result.message){
                    //console.log(result.message)
                    setUsernameUtenteUfficio("");
                    setUfficioId('0');
                    setPasswordUtenteUfficio("");
                    setConfirmationMessage(result.message)
                    setErrorMessage('');
                }else{
                    setErrorMessage(result.error);
                    setConfirmationMessage('')
                }
            })
            .catch(error => console.log('error', error));
        }
        if(usernameUtenteUfficio !== "" &&  mailUtente!=="") {
            //scelgo MSAL
            //controllo che sia una mail valida 
            //console.log('secondo')
            if (!mailUtente.match(regEmail)) {
                setErrorMessage("Inserire una mail valida");
                setConfirmationMessage('')
                return;
            }
            
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-user-id", userId);

            let urlencoded = new URLSearchParams();
            urlencoded.append("username", usernameUtenteUfficio);
            urlencoded.append("mail", mailUtente);
            urlencoded.append("ufficioId", ufficioId);

            const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
                };

            fetch(url+"api/v1/utentiUfficio", requestOptions)
            .then(response => {
                return response.json()
            })
            .then(result => {
                //console.log(result);
                if(result.message){
                    //console.log(result.message)
                    setUsernameUtenteUfficio("");
                    setUfficioId('0');
                    setMailUtente("");
                    setConfirmationMessage(result.message)
                    setErrorMessage('');
                }else{
                    setErrorMessage(result.error);
                    setConfirmationMessage('')
                }
            })
            .catch(error => console.log('error', error));
        }
    }
    return (
        <div className="Appp2"> 
            <div className="containerRight">
            <Header headImgs='true'></Header>
            <div className="containerCreazioneUffici" >
                <div className="innerdivContainerUffici">
                    In questa pagina puoi creare nuovi Uffici (moderatori) e/o Utenti-Ufficio (sottoUtenti appartenenti agli Uffici).
                    <div className="colonneCreazioneUffici">
                        <button  className={!createUfficio?'whiteButtons':'blueButtons'} style={{padding:' 0.5% 5%', fontSize:'large'}}  onClick={()=> setCreateUfficio(true)}>UFFICIO</button>
                        <button  className={createUfficio?'whiteButtons':'blueButtons'}  style={{padding:' 0.5% 5%', fontSize:'large'}} onClick={()=> setCreateUfficio(false)}>UTENTEUFFICIO</button>
                    </div>
                    {createUfficio&&<div className="colonnaUffici" style={{padding:'4%'}}>
                        <label htmlFor="usernameUfficio"> Insersci il nome dell'Ufficio: </label>
                        <input
                            value={usernameUfficio}
                            onChange={(event) => setUsernameUfficio(event.target.value)}
                            type="text"
                            id="usernameUfficio"
                            className="inputUffici"
                        />
                        <br /> <br />
                        
                        <label htmlFor="password">Inserisci una Password che soddisfi i requisiti**: </label>
                        <input
                            value={passwordUfficio}
                            onChange={(event) => setPasswordUfficio(event.target.value)}
                            type={ "text"}
                            id="password"
                            className="inputUffici"
                        />

                        <p className="testoPasswordUffici"> **La Password deve contenere almeno 8 caratteri di cui: almeno
                        una minuscola, una maiuscola, un numero ed un carattere speciale
                        (!@#$%^&*). </p>
                            
                        <button
                            onClick={creaUfficio}
                            className="blueButtons " id="createOfficeButton" aria-label='Crea nuovo Ufficio'>
                            CREA UFFICIO
                        </button>
                    </div>}
                    {!createUfficio&&<div className="colonnaUffici">
                        <label htmlFor="toValue"> Seleziona l'Ufficio a cui associare il nuovo utente-Ufficio: </label>
                        <br /> 
                        <select name="to" id="toValue" onChange={e=> setUfficioId(e.target.value)}>
                            <option value={0}>--</option>
                            {listaUffici.map((ufficio)=>{
                                return <option key={ufficio.id} value={ufficio.id}>{ufficio.username}</option>
                            })}
                        </select>
                        <br /> 
                        <label htmlFor="usernameUtenteUfficio"> Insersci l'Username per l'Utente-Ufficio: </label>
                                <input
                                    value={usernameUtenteUfficio}
                                    onChange={(event) => setUsernameUtenteUfficio(event.target.value)}
                                    type="text"
                                    id="usernameUtenteUfficio"
                                    className="inputUffici"
                                />
                        <p style={{margin: '2% 0 0 0'}} >Scegli con quale modalità potrà fare login l'utente:</p>
                        <div className="colonneCreazioneUffici">
                            <div>
                                <b >Login STANDARD</b>
                                
                                <label style={{margin: '2% 0 0 0'}}  htmlFor="passwordUtenteUfficio" >Inserisci una Password che soddisfi i requisiti**: </label>
                                <input
                                    value={passwordUtenteUfficio}
                                    onChange={(event) => setPasswordUtenteUfficio(event.target.value)}
                                    type={ "text"}
                                    id="passwordUtenteUfficio"
                                    className="inputUffici"
                                    disabled={`${mailUtente!=''?'disabled':''}`}
                                    style={{borderBottom:`${mailUtente!=''?'solid 1px red':''}`, cursor:`${mailUtente!=''?'no-drop':''}`}}
                                />

                                <p className="testoPasswordUffici"> **La Password deve contenere almeno 8 caratteri di cui: almeno
                                una minuscola, una maiuscola, un numero ed un carattere speciale (!@#$%^&*). </p>
                            </div>
                            <div id="rightCreazioneUfficiComponent" >
                                <b >Login MSAL</b>
                                <div >
                                    <label  style={{margin: '2% 0 0 0'}} htmlFor="asd">Inserisci la Email aziendale: </label>
                                    <input
                                        value={mailUtente}
                                        onChange={(event) => setMailUtente(event.target.value)}
                                        type={ "text"}
                                        id="asd"
                                        className="inputUffici"
                                        disabled={`${passwordUtenteUfficio!==''?'disabled':''}`}
                                        style={{borderBottom:`${passwordUtenteUfficio!==''?'solid 1px red':''}`, cursor:`${passwordUtenteUfficio!==''?'no-drop':''}`}}
                                    />
                                </div>
                            </div>
                        </div>

                        <button
                            onClick={creaUtenteUfficio}
                            className="blueButtons" id="creaUtenteUfficioButton"  aria-label='Crea nuovo Utente Ufficio'>
                            CREA UTENTE-UFFICIO
                        </button>
                    </div>
                    }
                    <div style={{color:'green', textAlign:'center'}}>{confirmationMessage}</div>
                    <div style={{color:'red',textAlign:'center'}}>{errorMessage}</div>
                </div>
            </div>
            </div>
        </div>
        );
    }

export default CreaUffici;


/* 
/regex per il controllo dell'email
.match(regEmail)
*/