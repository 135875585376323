
import {BrowserRouter as Router,Switch,Route} from "react-router-dom"; //il pacchetto ReactDOM viene importato per renderizzare i componenti React.

import HomePage from './Pages/HomePage.js';
import BachecaPage from './Pages/BachecaPage.js';
import MessaggiPage from './Pages/MessaggiPage.js';
import MessaggiSuperadminPage from './Pages/MessaggiSuperadmin.js';
import NewMessagePage from './Pages/NewMessagePage.js';
import NewNoticePage from './Pages/NewNoticePage.js';
import ChatPage from './Pages/ChatPage.js';
import ChatPageSuperadmin from './Pages/ChatPageSuperadmin.js';
import SearchPage from './Pages/SearchPage.js';
import StatsPage from './Pages/StatsPage.js';
import GestioneUtentiPage from './Pages/GestioneUtentiPage.js';
import CreazioneUffici from './Pages/CreazioneUffici.js';
import ModelloPage from './Pages/ModelloPage.js';
import AnonimePage from './Pages/AnonimePage.js';

import Sidebar from './Components/SideBar.js';
import "./style/LandscapeStyle.css"

import {UserProvider} from "./Context/UserContext.js";
import {ClickedMessageProvider} from "./Context/ClickedMessageContext.js";
import {RerenderProvider} from "./Context/RerenderAfterNewMsgContext.js";
import {SideMenuProvider} from "./Context/SideMenuContext.js";
import {SidebarRenderProvider} from "./Context/SidebarRenderContext.js";


//LA SIDEBAR, IN LOGIN E REGISTER NON VIENE RENDERIZZATA. QUESTO LO FACCIO RENDENDO FALSE -in homepage- LO STATO SIDEBARRENDER DICHIARATO NEL SIDEBARRENDERCONTEXT
function  App (){
  
  return(
    <Router>
      <div>
        <main>
          <Switch>
              <RerenderProvider><SideMenuProvider><SidebarRenderProvider><ClickedMessageProvider><UserProvider>
                <Sidebar></Sidebar> {/* in Login e Register verrà nascosta con css */}
                <Route exact path="/"                   render={(props) => <HomePage                  {...props} />}/>
                <Route exact path="/bacheca"            render={(props) => <BachecaPage               {...props} />}/>
                <Route exact path="/messaggi"           render={(props) => <MessaggiPage              {...props} />}/>
                <Route exact path="/messaggiSuperadmin" render={(props) => <MessaggiSuperadminPage    {...props} />}/>
                <Route exact path="/newmsg"             render={(props) => <NewMessagePage            {...props} />}/>
                <Route exact path="/newnot"             render={(props) => <NewNoticePage             {...props} />}/>
                <Route exact path="/chat"               render={(props) => <ChatPage                  {...props} />}/>
                <Route exact path="/chatSuperadmin"     render={(props) => <ChatPageSuperadmin        {...props} />}/>
                <Route exact path="/search"             render={(props) => <SearchPage                {...props} />}/>
                <Route exact path="/stats"              render={(props) => <StatsPage                 {...props} />}/>
                <Route exact path="/gestione"           render={(props) => <GestioneUtentiPage        {...props} />}/>
                <Route exact path="/uffici"             render={(props) => <CreazioneUffici           {...props} />}/>
                <Route exact path="/modello231"         render={(props) => <ModelloPage               {...props} />}/>
                <Route exact path="/anonime"            render={(props) => <AnonimePage               {...props} />}/>
              </UserProvider></ClickedMessageProvider></SidebarRenderProvider></SideMenuProvider></RerenderProvider>
          </Switch>
        </main>
      </div>
    </Router>
    )
};

export default App;
