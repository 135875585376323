import React from "react";
import { Document, Page, View, Text,  StyleSheet } from '@react-pdf/renderer'

export function PdfDocument(props) {

    // Create styles
    const styles = StyleSheet.create({
        title:{
            color: '#0077bc',
            fontWeight: 'bolder', /* IN REALTA NON FNUNZIONA */
            margin: '1%'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        }
    })

    return (
      <Document>
        <Page size="A6">
            <View  style={styles.section}>
                <Text style={styles.title}>Hermès</Text>
                <br/>
                <br/>
                <Text>USERNAME: <br/> {props.username}</Text>
                <Text >PASSWORD:  <br/>{props.password}</Text>
            </View>
        </Page>
      </Document> 
    );
}