import React, {useState, createContext} from 'react';

export const UserContext = createContext();

export const UserProvider = props =>{

    //AUTOGENERO uno username con il nickname. Il fatto di dichiararlo nel context lo rende costante per tutta la ""sessione""" finchè non si preme LogOut che viene cancellato e riGenerato
    const nickname =  'user' + Math.random().toString().substring(2, 8);
    
    const [ruolo, setRuolo] = useState('user');
    const [username, setUsername] = useState(nickname);
    const [accessToken, setAccessToken] = useState('');
    const [userId, setUserId] = useState('');
    const [utentiUfficio, setUtentiUfficio] = useState([{}]); //{id:, username:''}
    
    var userObject= {
        ruolo: ruolo,
        setRuolo: setRuolo,
        username: username,
        setUsername: setUsername,
        accessToken: accessToken,
        setAccessToken: setAccessToken,
        userId: userId,
        setUserId: setUserId,
        utentiUfficio: utentiUfficio, 
        setUtentiUfficio:setUtentiUfficio
    }

    return(
        <UserContext.Provider value ={userObject}>
            {props.children}
        </UserContext.Provider>
    );
}