import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import "../style/SingleMessage.css";
import { ClickedMessageContext } from "../Context/ClickedMessageContext.js";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SingleMessage(props) {
    const clickedMessageCont = useContext(ClickedMessageContext);//props.location.state;
    const setClickedMessage = clickedMessageCont.setClickedMessage;

    let history = useHistory();

    //formatto orario creazione
    let epoch = props.epoch;
    let data = new Date(+epoch).toLocaleString("it-IT");
    let orario = data.split(", ")[1].split(":");
    orario = orario[0] + ":" + orario[1];
    data = data.split(", ")[0];

    //formatto consensus
    let consensus=''
    if (props.consensus) {
        consensus = "SI";
    } else {
        consensus = "NO";
    }
    //formatto username di chi ha inviato o ricevuto il msg
    const nickname = props.usernameFrom;
    

    //formatto il tipo di messaggio
    const typeMap = {
        1: "Segnalazione",
        2: "Reclamo",
        3: "Suggerimento",
        4: "Altro",
    };
    const colorMapType = { 1: "red", 2: "blue", 3: "green", 4: "yellow" };

    const type = typeMap[props.type];
    const colorType = colorMapType[props.type];

    function handleClick() {
        setClickedMessage({
            id: props.id,
            subject: props.subject,
            text: props.text,
            data: data,
            orario: orario,
            from: props.from,
            to: props.to,
            consensus: consensus,
            type: type,
            usernameFrom: nickname,
            usernameTo: props.usernameTo
        });
        history.push("/chatSuperadmin");
    }

    return (
        <>
            <div className="singleMessage" onClick={()=>handleClick()}>
                <div></div>
                <div className="fromMessage">
                <FontAwesomeIcon
                    icon={faUser}
                    className="iconeCardNotices"
                ></FontAwesomeIcon>
                {props.usernameFrom}
                </div>
                <div className="subjectMessage">{props.subject}</div>
                <div className="dateMessage">
                    <FontAwesomeIcon
                        icon={faClock}
                        className="iconeCardNotices"
                        id="orologioIcon"
                    ></FontAwesomeIcon>
                    {orario + " - " + data}
                        <>
                        <div
                            className="messageTypeCircle"
                            style={{ background: colorType }}
                        ></div>
                        <div className="tooltipTypeMessage">{type}</div>
                        </>
                </div>
                <div></div>
                <div></div>
                {props.text!=''?<div className="textShortform">{props.text}</div>:<div className="textShortform">Immagine</div>}

            </div>
        </>
    );
}
export default SingleMessage;
