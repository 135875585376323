import React from 'react';
import { Link } from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';

import { UserContext} from "../Context/UserContext.js";
import PaginationArrows from "../Components/ArrowsPagination";
import SingleMessage from "../Components/SingleMessage";

import { faSearch} from "@fortawesome/free-solid-svg-icons";
import { faClock} from "@fortawesome/free-regular-svg-icons";
import { faUsers} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'

function SentContainer(){

  const userObject = useContext(UserContext);
  const {userId, ruolo, accessToken} = userObject

  const [loading , setLoading] = useState(true)
  var url=baseUrl

    //inizalizzo stati che mi servono LOCALMENTE
  const [listaMsg, setListaMsg] = useState([]);
  const [listaVuota, setListaVuota] = useState(false);
  
  //stati per la pagination
  const [offsetCounter, setOffsetCounter] = useState(0); //modificato solo in Pagination
  const dataLimit = 6; //modificabile anche dallo user un giorno
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0); //mi esce dalla fetch
  
  /* SET TOTALCOUNT: chiamata per sapere il totale dei msg da usare in PAGINATION ---> getTotalCountInboxOrSent*/
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("x-access-token",accessToken);
    myHeaders.append("x-from", userId);
    myHeaders.append("x-ruolo", ruolo);


    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(url+"api/v1/messages/totalCount", requestOptions)
      .then(response => response.json())
      .then(result => {
        //console.log("CONTO TOTALE sent",result.count);
        setTotalCount(result.count);
      })
      .catch(error => console.log('error', error));
  },[listaMsg])

  useEffect(()=>{ 
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
      myHeaders.append("x-access-token",  accessToken); 
      myHeaders.append("x-from", userId);
      myHeaders.append("x-ruolo", ruolo);

      myHeaders.append("x-offsetinput", offsetCounter);
      myHeaders.append("x-dataLimit", dataLimit);
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(url +"api/v1/messages/sent/all", requestOptions)
      .then(response =>{
          if(response.status === 200){
            return response.json();}
          else{
            return;
          }
      })
      .then(result => {
          //console.log("SENT",result.message);
          setLoading(false)

          if(result.message.length !== 0){
            var lista= Object.entries(result.message);
            lista= lista.map(item=>item[1]);
            setListaMsg(lista);
            setListaVuota(false); //sennò mi resta aperto il <p>
          }else if (result.message.length === 0){
           //console.log("non ricevuti",result.message);

            setListaVuota(true); //metterei anche setListaMsg([]); ti servirà nella gestione dei delete
            setListaMsg([]);
        }
      })
      .catch(error => console.log('error', error));
  },[offsetCounter]) 
  //console.log(listaMsg)

    return(
        <div className="fadeIn">
            <Link  to={{pathname:'/search', state:{inboxOrSent: "sent"}}} > <button className="blueButtons cerca" aria-label="Ricerca tra inviati"><FontAwesomeIcon icon={faSearch}></FontAwesomeIcon> </button> </Link >
            <div className="listaNot">
            <div className="headListaMessages">
                <FontAwesomeIcon icon={faUsers} id="usersIconSent"/> <div className="tooltipUsers">Destinatari</div>
                <FontAwesomeIcon icon={faClock} className="clockIconMsgs"/> <div className="tooltipClockMsgs">Data</div>
              </div>
              <br/>         
              {listaVuota && <p> Nessun messaggio inviato!</p>}
              {loading &&<div className="lds-dual-ring"></div>}
              {listaMsg.map(msg => (
                <SingleMessage  ruolo={ruolo} inboxOrSent={"sent"} msg={msg} key={msg.id} usernameTo = {msg.username}>
                </SingleMessage>))
              } 
            </div>
            <PaginationArrows totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={dataLimit} offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}></PaginationArrows>
        </div>
    );
}

export default SentContainer;
