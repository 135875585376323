import "../style/ContainerNewMsg.css"
import "../style/WhiteButtons.css";
import {useState, useEffect, useContext} from 'react';
import {useHistory,Link} from "react-router-dom";
import {UserContext} from "../Context/UserContext.js";
//import {SocketContext} from "../Context/SocketContext.js";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'

import { io } from "socket.io-client";

function ContainerNewMessage(){
    //inizializzo stati LOCALI
    const [consensus,setConsensus]= useState(false);
    const [notEmpty, setNotEmpty]= useState(false);
    const [testo, setTesto]= useState('');
    const [destinatario, setDestinatario]= useState(2);
    const [subject, setSubject] = useState('');
    const [tipo, setTipo]= useState(1);
    const [listaUffici, setListaUffici] =useState([])
    const [foto, setFoto] = useState([]);
    const [error, setError] = useState('')
    const maxFiles = 5
    const maxDimension = 10000000
    /* var url='https://hermes.avrgroup.it//'; */
    var url=baseUrl

    //stato per loader
    const [loading , setLoading] = useState(false)
    //richiamo Context per stati GLOBALI
    /* const [ruolo, setRuolo, username, setUsername, accessToken, setAccessToken, userId, setUserId] = useContext(UserContext); */
    const userObject = useContext(UserContext);
    const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    //quando clicco su Fatto reindirizzo alla MessaggiPage
    let history = useHistory();
    if(accessToken === ""){
        setRuolo(window.localStorage.ruolo); //sono già stringhe
        setUsername(JSON.parse(window.localStorage.username));
        setAccessToken(JSON.parse(window.localStorage.accessToken));
        setUserId(window.localStorage.userId); //sono già stringhe
    }
    
    var user = true;
    if (ruolo === 'moderator' || ruolo ==="admin"){
        user = false;
    }

    function handleSocket(){
        const socket = io(url)
        socket.emit("message", destinatario) 
        socket.emit('end');
    }

    function handleSubmit(){
        setLoading(true)

        //Se l'utente non compila il campo non manda messaggio ed esce un errore renderizzato in base a notEmpty
        if(!subject || (!testo && !foto) || destinatario === 0 ){
            console.log("campo vuoto");
            setNotEmpty(true)
            setLoading(false)

            return;
        }
        if(foto.length>maxFiles){
            console.log('Numero massimo di foto superato')
            setError(`Non puoi caricare più di ${maxFiles} foto!`)
            setLoading(false)

            return
        }
        setError('')

        //costruisco la fetch POST per creare nuovo msg
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);


        const formData = new FormData();
        formData.append("subject", subject);
        formData.append("text", testo);
        formData.append("from", userId);
        formData.append("to", destinatario);
        formData.append("consensus",consensus);
        formData.append("type", tipo);
        formData.append("role", ruolo);
        formData.append("fromutenteufficioid", window.localStorage.idUtenteUfficio?window.localStorage.idUtenteUfficio:null); //se sono utente ufficio 

        if(foto){
            formData.append("mediaType", 1);
        }else{
            formData.append("mediaType", 0);
        }

        for (let i = 0; i < foto.length; i++) {
            formData.append("images", foto[i]);
            //console.log('immagini', foto)
        }

        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            body: formData,
            redirect: 'follow'
        };

        fetch(url +"api/v1/messages/singolo", requestOptions)
        .then(response => response.json())
        .then(result => {
            setLoading(false)
            handleSocket()
            history.push('/messaggi');
        })
        .catch(error => console.log('error', error));
    }

    //prendo la lista di tutti gli users con ruolo moderator 
    useEffect(() =>{
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(url +"api/v1/users/moderators", requestOptions)
        .then(response => {
            //console.log('1111', response);

            if(response.status === 200){
                return response.json();
            }else{
                response.json().then((result)=>{
                    //console.log(result.message);
                })
                return;
            } 
        })
        .then(result => {
            //console.log(result.users)   
            setListaUffici(result.users);
        }) 
        .catch(error => console.log('error', error));
    },[accessToken])

    //per aggiungere immagini
    function handleInputImages(e){
        if (e.target.files.length > maxFiles) {
            //alert("Only 15 files accepted.");
            setFoto([])
            setError(`Non puoi caricare più di ${maxFiles} immagini!`)
            //e.preventDefault();
            return
        }
        for (let i = 0, numFiles = e.target.files.length; i < numFiles; i++) {
            const file = e.target.files[i];
            if(file.size>maxDimension){
                setFoto([])
                setError(`I files non possono superare singolarmente ${maxDimension/1000000}Mb`)
                return
            }
        }
        setFoto(e.target.files)
        setError('')
    }

    return ( 
        <div className="containerNewMsg">
            <div className="headNewMsg"> <h2 id="newMessTitle"> NUOVO MESSAGGIO</h2> </div> 
            <div className="bodyNewMsg">
                
                <h4>Destinatario</h4>
                <label style={{display:'none'}} htmlFor="toValue"> Seleziona un ufficio a cui inviare il messaggio </label>
                <select name="to" id="toValue" onChange={e=> setDestinatario(e.target.value)}>
                  {/*   <option value={2}>--</option> */}
                    {listaUffici.map((ufficio)=>{
                        return <option key={ufficio.id} value={ufficio.id}>{ufficio.username}</option>
                    })}
                </select>
                <h4>Tipo </h4>

                <label style={{display:'none'}} htmlFor="typeValue"> Seleziona il tipo di messaggio stai inviando</label>
                <select name="type" id="typeValue" onChange={e=> setTipo(e.target.value)}>
                    <option value={0}>--</option>
                    <option value="1">Segnalazione</option>
                    <option value="2">Reclamo</option>
                    <option value="3">Suggerimento</option>
                    <option value="4">Altro</option>
                </select>
                
                <h4>Oggetto</h4>
                <label style={{display:'none'}} htmlFor="textareaSubject"> Inserisci un oggetto</label>
                <textarea id="textareaSubject" value={subject} maxLength="75" onChange={e=> setSubject(e.target.value)}></textarea><br/>
                
                <label className="mostraUtentiButton" htmlFor='imagesInput'>Allega Immagini </label>
                <input type="file" id="imagesInput" accept="image/*" name="images" aria-label="File browser" style={{display:'none'}} multiple onChange={(e)=>handleInputImages(e)}></input>
                {(foto.length>0 && foto.length<6)&&<div style={{display:'inline'}}>Files selezionati: {foto.length}</div>}
                {error!==''&& <div style={{display:'inline', color:'red'}}>{error}</div>}

                <h4>Testo</h4>
                <label style={{display:'none'}} htmlFor="textareaTesto"> Inserisci un testo</label>
                <textarea id="textareaTesto" value={testo} onChange={e=> setTesto(e.target.value)}></textarea>

                {user &&
                <div id="consensusNewMsg" > 
                    <div >
                        <label className="main">DAI IL CONSENSO PER LA PUBBLICAZIONE
                            {/* <input type="checkbox" checked="checked"> </input>  */}
                            <input name="consensus" type="checkbox" id="checkboxConsensus" onChange={()=>setConsensus(!consensus)}></input>
                            <span className="geekmark"></span> 
                        </label>
                    </div>
                        
                    <FontAwesomeIcon icon={faInfoCircle} id= "faInfoConsensus"></FontAwesomeIcon>
                    <div className="infoConsensusTooltip">
                        Fornendo il consenso alla pubblicazione permetterai ai moderatori di aprire un avviso in bacheca 
                        riguardante la tematica su cui stai scrivendo. Ti assicuriamo che il tutto avverrà in forma completamente anonima.  
                    </div>
                </div>}
                <br/>
                {loading &&<div className="lds-dual-ring" style={{margin:'0'}}></div>}
                {!loading&&<div id="newMsgButtonsContainer">
                    <button className="blueButtons confermaDelete" type="submit" aria-label="Invia nuovo messaggio" onClick={()=>handleSubmit()}> <FontAwesomeIcon icon={faCheck} style={{marginRight:'1vw'}}></FontAwesomeIcon>INVIA</button> 
                    <Link to="messaggi"><button className="blueButtons confermaDelete" aria-label="Annulla"><FontAwesomeIcon icon={faTimes} style={{marginRight:'1vw'}}></FontAwesomeIcon>ANNULLA</button></Link><br/>
                </div>}
                {notEmpty && <p className="errorP">Compila tutti i campi!</p>} 

            
            </div>
        </div>
    );
}

export default ContainerNewMessage;




/*  

Ufficio Relazioni Esterne e Comunicazione
Ufficio Sicurezza, Ambiente e Sistemi di Gestione
Ufficio Acquisti e Gestione Fornitori
Ufficio Risorse Umane

Ufficio Gare e Societario
Ufficio Assistenza Gestionale
Ufficio Affari Legali e Rischi Aziendali

Ufficio Servizi Amministrativi e Bilancio
Ufficio Pianificazione Finanza e Controllo

*/