import React, { useEffect } from 'react';
import '../style/Pagination.css'

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/** parte della pagination nasce nel component dove viene chiamata la pagination:
 *  inbox e sent message mandano BE i valori di offset e datalimit
 *  i messaggi del superadmin invece fanno tutto FE
 */
function Pagination(props){
    
    const dataLimit = props.dataLimit;
    const totalCount = props.totalCount;
    const currentPage = props.currentPage;

    const setCurrentPage = props.setCurrentPage;
    const setOffsetCounter = props.setOffsetCounter;

    /**var per i colori delle frecce*/
    var [customColorArrowLeft, customColorArrowRight] = ["b9b9b9","0077bc"];
    var lastPage = Math.ceil(totalCount / dataLimit); 
    useEffect(()=>{
      setOffsetCounter((currentPage -1)*dataLimit);
    })
    var totalPagesNumber=[];

    const getPaginationGroup = () => {
        for (let i=1; i<= lastPage; i++){
          totalPagesNumber.push(i);
        }
        let paginationNumbers = []; //arrayNumbers

        paginationNumbers = totalPagesNumber
        return paginationNumbers; 
    }
    
    /*gestione colori frecce*/
    if(currentPage === 1 && currentPage === lastPage ){ //prima ma anche unica
      customColorArrowRight = "f9f9f9";
      customColorArrowLeft = "f9f9f9";
    }else if(currentPage === 1 && lastPage !== 0){ //prima pag
      customColorArrowLeft = "b9b9b9";
      customColorArrowRight = "616161";
    }else if(currentPage === lastPage && currentPage !==0) {//ultima pag 
      customColorArrowRight = "b9b9b9";
      customColorArrowLeft = "616161";
    }else if (lastPage === 0){
      customColorArrowRight = "f9f9f9";
      customColorArrowLeft = "f9f9f9";
    }else{ //nel mezzo
      customColorArrowLeft = "616161";
      customColorArrowRight = "616161";
    }

    function goToFirstPage(){
      setCurrentPage(1);
    }

    function goToPrevPage(){
      if (currentPage !== 1){ 
        setCurrentPage(currentPage - 1)
        }
    }

    function goToNextPage(){
      if (currentPage!== lastPage){
        setCurrentPage(currentPage + 1);
        }
    }

    function changePage(e){
      const pageNumber = Number(e.target.textContent);
      setCurrentPage(pageNumber);
    }

    function goToLastPage(){
      setCurrentPage(lastPage);
    }

    return(
        <div >
          <div className="paginationNumbers">
            <div onClick={()=> goToFirstPage()}>
              <FontAwesomeIcon icon={faChevronLeft} className={ 'arrowsPagination' } id="arrowLeft1" style={{color:customColorArrowLeft}}> 
              </FontAwesomeIcon>
              <FontAwesomeIcon icon={faChevronLeft} className={ 'arrowsPagination' } id="arrowLeft2" style={{color:customColorArrowLeft}}> 
              </FontAwesomeIcon>
            </div>

            <div onClick={()=>goToPrevPage()}> 
              <FontAwesomeIcon icon={faChevronLeft} className={ 'arrowsPagination' } id="arrowLeft3"style={{color:customColorArrowLeft}}> 
              </FontAwesomeIcon>
            </div>

            <div className="containerPaginationNumbers">
            {getPaginationGroup().map((item, index) =>(
              <span key={index} onClick={changePage} 
              className={`singleNumber ${currentPage === item ? 'active' : null}`}> {item}</span>
            ))}
            </div>

            <div onClick={()=>goToNextPage()}> 
              <FontAwesomeIcon icon={faChevronRight} className={ 'arrowsPagination' } id="arrowRight1" style={{color:customColorArrowRight}}>
              </FontAwesomeIcon> 
            </div>
          
            <div onClick={()=> goToLastPage()}>
              <FontAwesomeIcon icon={faChevronRight} className={ 'arrowsPagination' }  id="arrowRight2" style={{color:customColorArrowRight}}>
              </FontAwesomeIcon> 
              <FontAwesomeIcon icon={faChevronRight} className={ 'arrowsPagination' }  id="arrowRight3" style={{color:customColorArrowRight}}>
              </FontAwesomeIcon> 
            </div>
            </div>
           {/**  <p className="paginationNumbersInfo">Risultati da: {offsetCounter+1} a: {offsetCounter + dataLimit}</p> */}

        </div>
    )
}

export default Pagination;



/**
MAGIA DELLA PAGINATION

className={`singleNumber ${currentPage === item ? 'active' : null}`}  

 <PaginationArrows 
 totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={limitPages} 
 offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}>
 </PaginationArrows>
*/