import React from 'react';
import {useState, useEffect, useContext} from 'react';
import Modal from 'react-modal';

import {UserContext} from "../Context/UserContext.js";
import SingleNotice from "../Components/SingleNotice";
import BottoniRating from "../Components/BottoniRating";
import PaginationArrows from "../Components/ArrowsPagination";

import { faClock} from "@fortawesome/free-regular-svg-icons";
import { faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import baseUrl from '../utilities/globalVariables.js'
import {useHistory} from "react-router-dom";
import ModaleDiConferme from '../Components/ModaleDiConferme.js';

function NoticesContainerDate(props){

  const userObject = useContext(UserContext);
  const {userId, ruolo, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject
  
  let history = useHistory()

  if(accessToken === ""){
      if(window.localStorage.accessToken){
        setRuolo(window.localStorage.ruolo); //sono già stringhe
        setUsername(JSON.parse(window.localStorage.username));
        setAccessToken(JSON.parse(window.localStorage.accessToken));
        setUserId(window.localStorage.userId); //sono già stringhe
      } else {
          history.push ("/")
      }
  }


    //variabili per render condizionale 
    var userController =false;
    if(ruolo === 'user'){ 
      userController=true
    }

    //Se Data o Rate viene fatta una fetch diversa (in base al bottone cliccato viene modificato lo stato orderedByDate)
    const orderedByDate= props.orderedByDate; //boolean
    var primoUrl=baseUrl
    var url;
    if (orderedByDate){
      url= primoUrl +"api/v1/notices/all/byDate";
    } else{
      url= primoUrl+"api/v1/notices/all/byRate";
    }
    const urlFetch= primoUrl + "api/v1/notices/single";

    const [noticesList, setNoticesList] = useState([]);
    const [listaVuota, setListaVuota] = useState(false); 
    const [clickedNotice, setClickedNotice] = useState({}); /**per passare le info al popUp */
    //openPopUp gestisce apertura e chiusura del Modal popup contenente le info sull'avviso cliccato
    const [openPopUp, setOpenPopUp] = useState(false);
    //openConfirmationModal gestisce apertura  e chiusura del modal di conferma se cliccato su Elimina uno specifico avviso 
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
    const [loading , setLoading] = useState(false)
  
    //stati per la pagination
    const [offsetCounter, setOffsetCounter] = useState(0); //modificato solo in Pagination cambia in base alla currentPage
    const dataLimit = 4; //modificabile anche dallo user un giorno
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0); //mi esce dalla fetch

    //stile per il Modale
    const customStylePopup = {
      overlay:{
        backgroundColor      : 'rgb(255 255 255 / 0%)',
        backdropFilter       : 'blur(4px)',
        zIndex               : '2'
      }
    };

    function handleClosePopUp(){ 
      setOpenPopUp(false);
      setClickedNotice({});
    }

    function handleOpenConfirmatioModal(){
      setOpenConfirmationModal(true);
      setOpenPopUp(false);
    }

    function handleCloseConfirmationModal(){
      setOpenConfirmationModal(false);
      setClickedNotice({});
    };

    useEffect(()=>{ /**get all notices */
        setLoading(true);

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        myHeaders.append("x-offsetinput", offsetCounter);
        myHeaders.append("x-dataLimit", dataLimit);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
      
      fetch(url, requestOptions)
        .then(response =>{
          if(response.status === 200){
            return response.json();
          }else{
            return;
          }
        })
        .then(result => {
          //console.log("conto e lista avvisi in Bacheca",result.message);
          if (result.message){
            setTotalCount(result.message.count);
            var lista= Object.entries(result.message.rows);
            lista = lista.map(item=>item[1]);


            setNoticesList(lista);
            //console.log('noticesList', noticesList)
            setListaVuota(false);
            
          } else if(!result.message || result.message === undefined) {

            setNoticesList([]);
            setTotalCount(0);
            setListaVuota(true);
          }
          setLoading(false)
        })
        .catch(error => console.log('error', error));
    },[ accessToken, openPopUp, openConfirmationModal,  orderedByDate, offsetCounter])


    useEffect(() => {
      setCurrentPage(1);
      setOffsetCounter(0);
    }, [orderedByDate])

    function handleDeleteNotice(){
      //console.log(clickedNotice.id)
      var myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("x-user-id", userId);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            myHeaders.append("x-utenteufficio-id",  window.localStorage.idUtenteUfficio?window.localStorage.idUtenteUfficio:null);

            var urlencoded = new URLSearchParams();
            urlencoded.append("noticeId", clickedNotice.id);

            var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };

            fetch(urlFetch, requestOptions)
            .then(response => response.text())
           // .then(result => console.log(result))
            .then(()=>{
                handleCloseConfirmationModal();
            })
            .catch(error => console.log('error', error));
    }

    return(
        <>
            <div className="listaNot">
              <div className="headListaNotices">
                  <div id="topicIcon"> TOPIC
                  </div>
                  <div className="tooltipTopic"> Sintesi sull'argomento dell'avviso.</div>
                  
                  <div id="starIcon">
                    <FontAwesomeIcon icon={faThumbsUp} style={{marginLeft:'3vw'}}/> 
                    {/* <FontAwesomeIcon icon={faStarHalfAlt}  style={{marginLeft:'1vw'}}/> */}
                    <FontAwesomeIcon icon={faThumbsDown}  style={{marginLeft:'1vw'}} /> 
                  </div>
                  <div className="tooltipStarsRating">In verde, il totale dei voti positivi. In rosso, i negativi.</div>
                  <FontAwesomeIcon icon={faClock}  id="clockIcon"/>
                  <div className="tooltipClock"> Data di pubblicazione dell'avviso in bacheca.</div>
              </div><br/>
              
              {loading &&<div className="lds-dual-ring"></div>}
              {listaVuota && <p> Nessun avviso è ancora stato pubblicato!</p>}
              {noticesList.map(not => (
                <SingleNotice setClickedNotice={setClickedNotice} setOpenPopUp={setOpenPopUp} 
                              key={not.id} id={not.id} text={not.text} title={not.title} rating={not.rating} date={not.createdAt} epoch={not.epoch} positivesRates={not.positivesRates} negativesRates={not.negativesRates}>
                </SingleNotice>))} 
            </div>

            <Modal isOpen={openPopUp} onRequestClose={handleClosePopUp} style={customStylePopup} className="modalOpenNotice" ariaHideApp={false}>
              <div id="closePopUp" onClick={()=>handleClosePopUp()}>&times;</div>
                <div id ="divTopicNot">
                  <h3 id="clickedNoticeTitle">{clickedNotice.title} </h3> 
                  {userController &&<BottoniRating noticeId={clickedNotice.id}></BottoniRating>}
                  {!userController && <><button id ="delNotMod" className="blueButtons" onClick={()=> handleOpenConfirmatioModal()}> <FontAwesomeIcon icon={faTrashAlt} style={{marginRight:'0.5vw'}}/> ELIMINA</button></> }
                </div>
                <div id="contenutoSingleNotice">
                  {/* <h4 style={{display:'inline'}}>RATE COMPLESSIVO:</h4> {clickedNotice.rating} <br/> */}
                  <FontAwesomeIcon icon={faClock} id="faClockIcon"  />{clickedNotice.orario +"  "+ clickedNotice.data} <br/>
                  <div className="textContainer">{clickedNotice.text}
                </div>
              </div>
              
            </Modal>

            {openConfirmationModal&&<ModaleDiConferme testo={'Sei davvero sicurə di voler eliminare questo avviso?'} setModale = {setOpenConfirmationModal} funzione ={handleDeleteNotice}></ModaleDiConferme>}
            
            <PaginationArrows totalCount={totalCount} currentPage={currentPage} setCurrentPage={setCurrentPage} dataLimit={dataLimit} offsetCounter={offsetCounter} setOffsetCounter={setOffsetCounter}></PaginationArrows>
        </>
    );
};

export default NoticesContainerDate;