import React, {  useContext } from "react";
import { useHistory } from "react-router-dom";
import "../style/SingleMessage.css";
import { ClickedMessageContext } from "../Context/ClickedMessageContext.js";

import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* function createMarkup() {
    return {__html: 'First &middot; Second'};
  }
 */
function SingleMessage(props) {
    const [ruolo, inboxOrSent] = [ props.ruolo, props.inboxOrSent];
    //console.log(props)
    const { msg } = props;
    const clickedMessageCont = useContext(ClickedMessageContext);//props.location.state;
    const setClickedMessage = clickedMessageCont.setClickedMessage;

    //console.log("inboxOrSent", inboxOrSent);
    let history = useHistory();

    //formatto orario creazione
    var epoch = msg.epoch;
    var data = new Date(+epoch).toLocaleString("it-IT");
    var orario = data.split(", ")[1].split(":");
    orario = orario[0] + ":" + orario[1];
    data = data.split(", ")[0];

    //formatto consensus
    var consensus
    if (msg.consensus) {
        consensus = "SI";
    } else {
        consensus = "NO";
    }
    //formatto username di chi ha inviato o ricevuto il msg
    var nickname;
    if (props.usernameFrom) {
        nickname = props.usernameFrom;
    } else if (props.usernameTo) {
        nickname = props.usernameTo;
    }

    //formatto il tipo di messaggio
    const typeMap = {
        1: "Segnalazione",
        2: "Reclamo",
        3: "Suggerimento",
        4: "Altro",
    };
    const colorMapType = { 1: "red", 2: "blue", 3: "green", 4: "yellow" };

    var type = typeMap[msg.type];
    var colorType = colorMapType[msg.type];
    var renderType = false;

    //formatto var ruolo per renders condizionali
    if (ruolo === "user" && inboxOrSent === "sent") {
        renderType = true;
    } else if (ruolo === "moderator" && inboxOrSent === "inbox") {
        renderType = true;
    }
    var inbox = false;
    var sent = false;
    if (inboxOrSent === "inbox") {
        inbox = true;
        //console.log(inbox)
    }
    if (inboxOrSent === "sent") {
        sent = true;
    }

    function handleClick() {
        setClickedMessage({...msg, nickname:nickname, data:data, consensus:consensus, type: type});
        history.push("/chat");
    }

    return (
        <>
        {inbox && msg.flag && (
            <div className="singleMessage" onClick={()=>handleClick()}>
                <div className="busta">
                    <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="fromMessage">
                    <FontAwesomeIcon
                        icon={faUser}
                        className="iconeCardNotices"
                    ></FontAwesomeIcon>
                    {props.usernameFrom}
                    {props.usernameTo}
                </div>
                <div className="subjectMessage" style={{ fontWeight: "400" }}>
                    {msg.subject}
                </div>
                <div className="dateMessage">
                    <FontAwesomeIcon
                        icon={faClock}
                        className="iconeCardNotices"
                        id="orologioIcon"
                    ></FontAwesomeIcon>
                    {orario + " - " + data}
                    {renderType && (
                        <>
                        <div
                            className="messageTypeCircle"
                            style={{ background: colorType }}
                        ></div>
                        <div className="tooltipTypeMessage">{type}</div>
                        </>
                    )}
                </div>
                <div></div>
                <div></div>
                {msg.text!==''?<div className="textShortform">{msg.text}</div>:<div className="textShortform">Immagine</div>}
            </div>
        )}
        {inbox && !msg.flag && (
            <div className="singleMessage" onClick={()=>handleClick()}>
                <div className="busta"></div>
                <div className="fromMessage">
                <FontAwesomeIcon
                    icon={faUser}
                    className="iconeCardNotices"
                ></FontAwesomeIcon>
                {props.usernameFrom}
                {props.usernameTo}
                </div>
                <div className="subjectMessage">{msg.subject}</div>
                <div className="dateMessage">
                    <FontAwesomeIcon
                        icon={faClock}
                        className="iconeCardNotices"
                        id="orologioIcon"
                    ></FontAwesomeIcon>
                    {orario + " - " + data}
                    {renderType && (
                        <>
                        <div
                            className="messageTypeCircle"
                            style={{ background: colorType }}
                        ></div>
                        <div className="tooltipTypeMessage">{type}</div>
                        </>
                    )}
                </div>
                <div></div>
                <div></div>
                {msg.text!==''?<div className="textShortform">{msg.text}</div>:<div className="textShortform">Immagine</div>}

            </div>
        )}
        {sent && (
            <div className="singleMessage" onClick={()=>handleClick()}>
                <div className="busta"></div>
                <div className="fromMessage">
                <FontAwesomeIcon
                    icon={faUser}
                    className="iconeCardNotices"
                ></FontAwesomeIcon>
                {props.usernameFrom}
                {props.usernameTo}
                </div>
                <div className="subjectMessage">{msg.subject}</div>
                <div className="dateMessage">
                <FontAwesomeIcon
                    icon={faClock}
                    className="iconeCardNotices"
                    id="orologioIcon"
                ></FontAwesomeIcon>
                {orario + " - " + data}
                {renderType && (
                    <>
                    <div
                        className="messageTypeCircle"
                        style={{ background: colorType }}
                    ></div>
                    <div className="tooltipTypeMessage">{type}</div>
                    </>
                )}
                </div>
                <div></div>
                <div></div>
                {msg.text!==''?<div className="textShortform">{msg.text}</div>:<div className="textShortform">Immagine</div>}

            </div>
        )}
        </>
    );
}
export default SingleMessage;
