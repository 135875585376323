
import React from 'react';
import Modal from "react-modal";

import {useState, useContext, useEffect} from 'react';
import {useHistory} from "react-router-dom";
import {UserContext} from "../Context/UserContext.js";
import {SidebarRenderContext} from "../Context/SidebarRenderContext.js";
import"../style/AnonimePage.css";

import Header from '../Components/Header.js';
import baseUrl from '../utilities/globalVariables.js'


function AnonimePage() {
    const userObject = useContext(UserContext);
    const {userId, setRuolo, setUsername, accessToken, setAccessToken, setUserId} = userObject

    const sideBarContext = useContext(SidebarRenderContext);
    const setSidebarRender = sideBarContext.setSidebarRender;
    const [openModal, setOpenModal]= useState(false)
    var url=baseUrl

    //renderizza la SideBar
    useEffect(()=>{
        setSidebarRender(true);
    },[])

    let history = useHistory();

    const customStyleInfoModal = {
        overlay: {
            backgroundColor: "rgb(255 255 255 / 0%)",
            backdropFilter: "blur(4px)",
            zIndex: "2",
        },
    };
    if(window.localStorage.length === 0 || window.localStorage.username===undefined){
        history.push('/');
    }

    if(accessToken === ""){
        setRuolo(window.localStorage.ruolo); //sono già stringhe
        setUsername(JSON.parse(window.localStorage.username));
        setAccessToken(JSON.parse(window.localStorage.accessToken));
        setUserId(window.localStorage.userId); //sono già stringhe
    }

    const [listaAnonime, setListaAnonime] = useState([])
    const [loading , setLoading] = useState(false)
    const [listaVuota, setListaVuota] = useState(''); 
    const [clickedId, setClickedId] = useState(''); 
    const [rerender, setRerender] = useState(true)


    //PRENDO TUTTE LE RICHIESTE (inviate dalla pagina di login) ANONIME
    useEffect(() => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);
        
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
    
        fetch(url+'api/v1/anonymous', requestOptions)
        .then(response =>{
            if(response.status === 200){
                return response.json();
            }else{
                return;
            }
        })
        .then(result => {
            //console.log(result)
            setListaAnonime(result.message)
            if(result.message.length===0){
                setListaVuota('Nessuna segnalazione è ancora stata inviata')
            }
            setLoading(false)
        })
        .catch(error => console.log('error', error));

    },[accessToken, rerender])

//C'è LA POSSIBILITA DI ARCHIVIARLE
    function handleStoreAnonyme(){
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var urlencoded = new URLSearchParams();
        urlencoded.append("anonymeId", clickedId);
        
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
    
        fetch(url+'api/v1/anonymous', requestOptions)
        .then(response =>{
            if(response.status === 200){
                return response.json();
            }else{
                return;
            }
        })
        .then((response) =>{
            //console.log(response)
            setOpenModal(false)
            setRerender(!rerender)
        })
        .catch(error => console.log('error', error));

    }

        return (
        <div className="Appp2"> 
            <div className="containerRight">
            <Header headImgs='true'></Header>
            <div className="containersVariAnonime">
                <div className="divContainerAnonime">
                    <div className="headAnonimePage">Qui puoi prendere visione di tutte le segnalazioni anonime che sono state inviate. Possono essere archiviate, ma non ripristinate. Fai attenzione!</div>

                    <div className="containerAnonimePage">
                    {loading &&<div className="lds-dual-ring"></div>}
                    <p><b>{listaVuota}</b></p>
                    {listaAnonime.map(segnalazione=>{
                        var epoch = segnalazione.epoch;
                        var data = new Date(+epoch).toLocaleString('it-IT');
                        var orario = data.split(", ")[1].split(":");
                        orario = orario[0] +":"+ orario[1];
                        data = data.split(", ")[0];

                    return(
                        <div className="singleSegnalazioneAnonima"  key={segnalazione.id}>
                            <span>{segnalazione.id}</span>
                            <span>{orario+" - "+data}</span>
                            <span className="archiviaButtonSegnalazioneAnonima" onClick={()=>{setOpenModal(true); setClickedId(segnalazione.id)}} ><b>Archivia</b></span>
                            <span className="textSegnalazioneAnonima">{segnalazione.text}</span>
                        </div>
                    )
                    })}
                    <Modal isOpen={openModal} style={customStyleInfoModal} className="infoModalRegistration anonime" ariaHideApp={false}  onRequestClose={() => setOpenModal(false)} >
                        Sei sicuro di voler archiviare questa segnalazione? L'operazione non sarà reversibile! 
                        <div>
                            <button className="blueButtons anonymeButtons" onClick={()=>handleStoreAnonyme()}> SI, ARCHIVIA
                            </button>
                            <button className="blueButtons anonymeButtons" onClick={()=>setOpenModal(false)}>  NO, ANNULLA
                            </button>
                        </div>
                    </Modal>
                    
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }

    export default AnonimePage;