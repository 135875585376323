
//Per ogni ufficio cliccato prendo la lista totale degli inbox con answered true 
//e poi  nel rendering faccio createdat - answeredAt per sapere tempistica media di risposta.

import React, { useState, useEffect, useContext } from "react";
import "../style/StatsPage.css";
import baseUrl from '../utilities/globalVariables.js'

import { UserContext } from "../Context/UserContext.js";

function TempisticheComponent() {
    
    const userObject = useContext(UserContext);
    const {userId, accessToken} = userObject

    const [listaUffici, setListaUffici] =useState([])
    const [listaMessaggi, setListaMessaggi] =useState([])
    const [nessunMessaggio, setNessunMessaggio] =useState('')

    var url=baseUrl


    useEffect(() =>{
        //prendo la lista di tutti gli users con ruolo moderator per popolare il select
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url+"api/v1/users/moderators ", requestOptions)
        .then(response => {
            return response.json();
        })
        .then(result => {
            setListaUffici(result.users);
        }) 
        .catch(error => console.log('error', error));
    },[accessToken])


    //PRENDO TUTTE LE CONVERSAZIONI INBOX (DOVE ANSWERED ===TRUE),  nel map poi per ogni conversazione prendo il 1 msg e il terzo e sottrai le epoch
    function callAPI(e){
        if(e.target.value===0){
            setNessunMessaggio("Nessun ufficio selezionato")
            setListaMessaggi([])
            return
        }
        var officeOption = e.target.value;

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", accessToken);
        myHeaders.append("x-user-id", userId);
        myHeaders.append("x-to", officeOption);


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(url+"api/v1/admin/messages", requestOptions)
        .then(response => {
            return response.json();
        })
        .then(result => {
            //console.log("result", result.response);   
            setListaMessaggi(result.response)
            if(result.response.length===0){
                setNessunMessaggio("L'ufficio non ha ancora ricevuto nessun messaggio o non ha ancora risposto")
            }else{
                setNessunMessaggio('')
            }
        }) 
        .catch(error => console.log('error', error));   
        
    }

    return (
        <div className="statsPageContainer">
            <label htmlFor="" className="testoTempistiche">Selezionare l'Ufficio sul quale si vuol avere informazioni.
                <select id="listaUffici" onChange={(e)=>callAPI(e)} className="selectTempistiche">
                    <option value="0">---</option>
                    {listaUffici.map((ufficio)=>{
                        return <option key={ufficio.id} value={ufficio.id} className="listaUfficiTempistiche">{ufficio.username}</option>
                    })}
                </select>
            </label>
            <div className="containerTempistiche">
                <div className="headContainerTempistiche"><span>ID</span><span>OGGETTO CONVERSAZIONE</span><span>NUMERO DI ORE INTERCORSE TRA RICEZIONE E PRIMA RISPOSTA:</span></div>
                <p style={{marginTop: '2%',marginLeft:'4%'}}>{nessunMessaggio}</p>
                {listaMessaggi.map((messaggio)=>{
                    let creazione= new Date(messaggio.createdAt).getTime();
                    let risposta = new Date(messaggio.answeredAt).getTime()
                    // To calculate the time difference of two dates
                    var difference_In_Time = risposta-creazione;
                    // To calculate the no. of days between two dates
                    var difference_In_Ore = difference_In_Time / (1000 * 3600);
                    var differenzaInDays = Math.floor(difference_In_Time / (1000 * 3600*24));
                    var oreArrotondate= Math.floor(difference_In_Ore)
                    //console.log( oreArrotondate ) 
                    return <div key={messaggio.id} className="bodyContainerTempistiche">
                        <span>{messaggio.id} </span> <span className="oggettoConversazioneTempistiche">{messaggio.subject}</span><span className="oreRisposta"> {oreArrotondate}h  ({differenzaInDays}d)</span> 
                        </div>
                })}
            </div>
        </div>
    );
}
export default TempisticheComponent;

